import { Fade } from '@material-ui/core';
import React from 'react';

const TextTransition = ({ children }) => (
  <Fade in unmountOnExit timeout={800}>
    {children}
  </Fade>
);

export default TextTransition;
