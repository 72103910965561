import React from 'react';
import {
  AppBar, Logo, ExternalLink, Typography, IconButton
} from 'bso-ui-kit';
import { Box } from '@material-ui/core';
import { ExitToApp } from '@material-ui/icons';
import PAGES from 'common/pages';

const Topbar = () => (
  <AppBar
    logo={<Logo color="primary-negative" size={100} />}
    nav={<div />}
  >
    <ExternalLink color="primary" href={PAGES.BSO}>
      <Typography variant="button" color="white">Exit portal</Typography>
    </ExternalLink>
    <ExternalLink disableUnderline href={PAGES.BSO}>
      <IconButton color="white">
        <Box marginLeft={1} paddingTop={0.5}>
          <ExitToApp />
        </Box>
      </IconButton>
    </ExternalLink>
  </AppBar>
);

export default Topbar;
