// InterfaceContext.js
import React, { useState, useContext } from 'react';

export const InterfaceContext = React.createContext();

export const InterfaceProvider = ({ children }) => {
  const [feedName, setFeedName] = useState('');
  const [backhaulStatus, setBackhaulStatus] = useState();
  const [selectedInterfaces, setSelectedInterfaces] = useState([]);

  return (
    <InterfaceContext.Provider value={{
      selectedInterfaces,
      setSelectedInterfaces,
      feedName,
      setFeedName,
      backhaulStatus,
      setBackhaulStatus
    }}
    >
      {children}
    </InterfaceContext.Provider>
  );
};

const useInterface = () => {
  const values = useContext(InterfaceContext);

  return values;
};

export default useInterface;
