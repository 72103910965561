import React from 'react';
import {
  Box,
  Grid,
  Card,
  CardHeader, CardContent
} from '@material-ui/core';
import Progress from 'components/Progress/Progress';
import { Typography } from 'bso-ui-kit';
import DRFTable from 'components/DRFTable/DRFTable';
import DRFRow from 'components/DRFTable/DRFRow';
import DRFCell from 'components/DRFTable/DRFCell';
import CustomLinearProgress from 'components/CustomLinearProgress/CustomLinearProgress';

const SecureEngress = ({ data }) => {
  const renderData = () => (
    <Box px={3}>
      <Typography variant="h5">
        Engress
      </Typography>
      <Grid container spacing={3} alignItems="stretch">
        <Grid item lg={12} xs={12}>
          <Card>
            <CardContent style={{ paddingTop: 0, overflowY: 'auto' }}>
              <DRFTable>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Transmitted Frames</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data.lane.landsideOutFrameCount}</Typography>
                  </DRFCell>
                </DRFRow>
              </DRFTable>
            </CardContent>
            <CardHeader
              disableTypography
              title={<Typography variant="h6">Primary Trunk</Typography>}
              style={{ paddingBottom: 0 }}
            />
            <CardContent style={{ paddingTop: 0, overflowY: 'auto' }}>
              <div style={{ paddingBottom: '16px' }}>
                <CustomLinearProgress
                  variant="buffer"
                  status={(data.lane.decapBufferTotalSize !== 0) ? 'ok' : 'error'}
                  current={(data.lane.decapBufferDataWidth / 8)
                    * data.lane.decapBufferUsage}
                  max={(data.lane.decapBufferDataWidth / 8)
                    * data.lane.decapBufferMaxUsage < data.lane.decapBufferUsage
                    ? data.lane.decapBufferUsage : data.lane.decapBufferMaxUsage}
                  total={(data.lane.decapBufferDataWidth / 8)
                    * data.lane.decapBufferMaxUsage > data.lane.decapBufferTotalSize
                    ? data.lane.decapBufferMaxUsage : data.lane.decapBufferTotalSize}
                />
              </div>
              <DRFTable>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Trunk Frames</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data.lane.primary10GTrunkInFrameCount}</Typography>
                  </DRFCell>
                </DRFRow>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Decap Frames</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data.lane.decapInFrameCount}</Typography>
                  </DRFCell>
                </DRFRow>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Corrupted Frames</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data.lane.decapInErrorCount}</Typography>
                  </DRFCell>
                </DRFRow>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Truncated Frames</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>
                      {data.lane?.decapTruncatedFrameCount ? data.lane.decapTruncatedFrameCount : '---'}
                    </Typography>
                  </DRFCell>
                </DRFRow>
              </DRFTable>
            </CardContent>
            <CardHeader
              disableTypography
              title={<Typography variant="h6">Backup Trunk</Typography>}
              style={{ paddingBottom: 0 }}
            />
            <CardContent style={{ paddingTop: 0, overflowY: 'auto' }}>
              <DRFTable>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Received Frames</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data.laneBackup.decapOutFrameCount}</Typography>
                  </DRFCell>
                </DRFRow>
              </DRFTable>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <>
      {
        data ? renderData()
          : <Progress />
      }
    </>
  );
};

export default SecureEngress;
