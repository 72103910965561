import API from 'common/api';
import useConnectErrorHandler from 'helpers/errorHandlers';
import { get, put } from 'helpers/query/fetchers';
import { useQuery, useMutation } from '@tanstack/react-query';

const useUser = () => {
  const { handleError } = useConnectErrorHandler();

  const user = useQuery(['user'], () => (
    get(API.PROFILE)
      .then((res) => res.data)
      .catch((err) => handleError(err))
  ));

  const { mutateAsync: setUser } = useMutation(({ url, data }) => put(url, data));

  return [{ ...user }, setUser];
};

export default useUser;
