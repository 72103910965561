import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';

const DeleteConfirmationDialog = ({
  id = null, title, contentText, isNotify = false, confirmText, children
}) => {
  const [state, setState] = useState({
    open: false,
    callback: null,
    sendMail: true
  });
  const [sendMail, setSendMail] = useState(true);

  const hide = () => {
    setState({
      open: false,
      callback: null
    });
  };

  const show = (callback) => (event) => {
    event.preventDefault();
    setState({
      ...state,
      open: true,
      callback: (param) => callback(param)
    });
  };

  const confirm = () => {
    if (state.callback) {
      state.callback(isNotify ? sendMail : null);
    }

    setSendMail(true);
    hide();
  };

  return (
    <>
      {children(show)}
      <Dialog open={state.open} onClose={hide} aria-labelledby="responsive-dialog-title" maxWidth="xs" fullWidth>
        <DialogTitle id="responsive-dialog-title">
          <div style={{ display: 'flex' }}>
            <Typography id={id} variant="h5">{title}</Typography>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{contentText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button id="NoButton" onClick={hide} color="primary" style={{ minWidth: '100px' }}>
            No
          </Button>
          <Button id="YesButton" onClick={confirm} color="primary" autoFocus style={{ minWidth: '100px' }}>
            {confirmText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteConfirmationDialog;
