import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { CheckCircle as CheckCircleIcon, Cancel as ErrorIcon, Warning } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 400,
    maxWidth: 500,
    padding: theme.spacing(1)
  },
  icon: {
    fontSize: 40,
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(1)
  },
  variantSuccess: {
    color: theme.palette.success.main
  },
  variantError: {
    color: theme.palette.error.main
  },
  variantInfo: {
    color: theme.palette.info.main
  },
  variantWarning: {
    color: theme.palette.warning.main
  }
}));

const capitalise = (text) => text.charAt(0).toUpperCase() + text.slice(1);

const SnackMessage = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const { closeSnackbar } = useSnackbar();
  const {
    id, message, variant, 'data-testid': testid
  } = props;

  const handleDismiss = () => {
    closeSnackbar(id);
  };

  const getIcon = () => {
    if (variant === 'success') {
      return <CheckCircleIcon className={classNames(classes.icon, classes[`variant${capitalise(variant)}`])} />;
    }
    if (variant === 'error') {
      return <ErrorIcon className={classNames(classes.icon, classes[`variant${capitalise(variant)}`])} />;
    } if (variant === 'warning') {
      return <Warning className={classNames(classes.icon, classes[`variant${capitalise(variant)}`])} />;
    }
    return <div />;
  };

  return (
    <Paper className={classes.paper} ref={ref}>
      {getIcon()}
      <Typography variant="body1" data-testid={testid}>{message}</Typography>
      <span style={{ flex: 1 }} />
      <IconButton onClick={handleDismiss}>
        <CloseIcon />
      </IconButton>
    </Paper>
  );
});

export default SnackMessage;
