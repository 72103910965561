import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import PAGES from 'common/pages';
import PrivateRouteWithLayout from 'components/RouteWithLayout/PrivateRouteWithLayout';
import RouteWithLayout from './components/RouteWithLayout';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  // Dashboard as DashboardView,
  Settings as SettingsView,
  // Interface as InterfaceView,
  InterfaceStatusView,
  InterfaceStatusContainer,
  SignUp as SignUpView,
  ConfirmSignUp as ConfirmSignUpView,
  ResendConfirmSignUp as ResendConfirmSignUpView,
  SignIn as SignInView,
  SignOut as SignOutView,
  ForgotPassword as ForgotPasswordView,
  ResetPassword as ResetPasswordView,
  NotFound as NotFoundView,
  NetworkMap as NetworkMapView
} from './views';

const Routes = () => (
  <Switch>
    <Redirect
      exact
      from="/"
      to={PAGES.INTERFACE_STATUS}
    />
    <PrivateRouteWithLayout
      component={InterfaceStatusView}
      exact
      layout={MainLayout}
      path={PAGES.INTERFACE_STATUS}
    />
    <PrivateRouteWithLayout
      component={InterfaceStatusContainer}
      exact
      layout={MainLayout}
      path={PAGES.INTERFACE_STATUS_DETAIL}
    />
    <PrivateRouteWithLayout
      component={InterfaceStatusContainer}
      exact
      layout={MainLayout}
      path={PAGES.INTERFACE_STATUS_INFORMATION}
    />
    <PrivateRouteWithLayout
      component={InterfaceStatusContainer}
      exact
      layout={MainLayout}
      path={PAGES.INTERFACE_CONFIGURE}
    />
    <PrivateRouteWithLayout
      component={SettingsView}
      layout={MainLayout}
      path={PAGES.SETTINGS}
    />
    <PrivateRouteWithLayout
      component={NetworkMapView}
      exact
      layout={MainLayout}
      path={PAGES.NETWORKMAP}
    />
    <RouteWithLayout
      component={SignUpView}
      exact
      layout={MinimalLayout}
      path={PAGES.SIGN_UP}
    />
    <RouteWithLayout
      component={ConfirmSignUpView}
      exact
      layout={MinimalLayout}
      path={PAGES.CONFIRM_SIGN_UP}
    />
    <RouteWithLayout
      component={ResendConfirmSignUpView}
      exact
      layout={MinimalLayout}
      path={PAGES.RESEND_CONFIRM_SIGN_UP}
    />
    <RouteWithLayout
      component={SignInView}
      exact
      layout={MinimalLayout}
      path={PAGES.SIGN_IN}
    />
    <RouteWithLayout
      component={SignOutView}
      exact
      layout={MinimalLayout}
      path={PAGES.SIGN_OUT}
    />
    <RouteWithLayout
      component={ForgotPasswordView}
      exact
      layout={MinimalLayout}
      path={PAGES.FORGOT_PASSWORD}
    />
    <RouteWithLayout
      component={ResetPasswordView}
      exact
      layout={MinimalLayout}
      path={PAGES.RESET_PASSWORD}
    />
    <RouteWithLayout
      component={NotFoundView}
      exact
      layout={MinimalLayout}
      path={PAGES.NOT_FOUND}
    />
    <Redirect to={PAGES.NOT_FOUND} />
  </Switch>
);

export default Routes;
