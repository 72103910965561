const API = {
  /* USERS */
  // USER_PERMISSIONS: '/permission/',
  // USER_INFO: '/me',
  // USER_AUTH_TOKEN: '/auth/jwt/',
  // USER_AVATAR: '/me',
  // USER_LIST: '/users/',
  LOGIN: '/auth/jwt/',
  REGISTER: '/register/',
  CONFIRM_SIGN_UP: '/verifyemail/',
  RESEND_CONFIRM_SIGN_UP: '/resendconfirmationemail/',
  FORGOT_PASSWORD: '/forgotpassword/',
  RESET_PASSWORD: '/resetpassword/',
  VERIFY_RESET_PASSWORD: '/verifyresetpasswordcode/',
  PROFILE: '/profile/',
  UPDATE_PROFILE: '/profile/update_profile/',
  CHANGE_PASSWORD: '/profile/change_password/',
  USER: '/user/',
  SET_ADMIN: (id, group) => `/user/${id}/admin/${group}/`,

  /* FEED */
  FEED: '/feed/',

  /* INTERFACE */
  INTERFACE: '/interface/',
  INTERFACE_STATUS: (id) => `/interface/${id}/get_info/`,
  INTERFACE_SET_STATUS: (id) => `/interface/${id}/set_status/`,
  INTERFACE_SET_DESCRIPTION: (id) => `/interface/${id}/set_description/`,
  INTERFACE_TYPE: '/interface-type/',
  INTERFACE_COMPRESSION_DYNAMIC: (id) => `/interface/${id}/compression`,

  /* DEVICE */
  DEVICE: '/device/',
  DEFAULT_THRESHOLD: '/threshold/',
  DEVICE_THRESHOLD: '/device-threshold/',
  LOGS: '/log/',
  GRAPH: '/interface/'
};

export default API;
