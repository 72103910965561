import React from 'react';
import Filter from 'components/Filter/Filter';

const UserFilter = () => {
  const filterConfig = [
    {
      name: 'search',
      label: 'Search',
      type: 'string'
    },
  ];

  return <Filter filterConfig={filterConfig} />;
};

export default UserFilter;
