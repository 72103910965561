import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PAGES from 'common/pages';
import useAuth from 'hooks/useAuth';

const PrivateRouteWithLayout = ({ layout: Layout, component: Component, ...rest }) => {
  const { isSignedIn } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) => (
        isSignedIn() ? (
          <Layout>
            <Component {...props} {...rest} />
          </Layout>
        )
          : (
            <Redirect to={{ pathname: PAGES.SIGN_IN, state: { from: props.location } }} />
          ))}
    />
  );
};

export default PrivateRouteWithLayout;
