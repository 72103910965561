import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Settings } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import {
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  listItem: {
    color: theme.palette.primary.main,
  },
}));

const Configure = ({ data }) => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();

  return (
    <ListItem
      button
      onClick={() => {
        history.push({
          pathname: (location.pathname.endsWith('/')
            ? `${location.pathname}configure/${data.id}`
            : `${location.pathname}/configure/${data.id}`),
          search: location.search
        });
      }}
    >
      <ListItemIcon className={classes.listItem}><Settings /></ListItemIcon>
      <ListItemText primary="(De)compression" />
    </ListItem>
  );
};

export default Configure;
