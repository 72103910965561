/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  ArrowForward, ClearAll, Close, Tune
} from '@material-ui/icons';
import {
  Dialog,
  DialogContent,
  Hidden,
  Paper,
  Typography,
  List,
  ListItem,
  Slide,
  DialogActions
} from '@material-ui/core';

import FormFieldFactory from 'components/Form/FormFieldFactory';
import { useLocation, useHistory } from 'react-router-dom';
import { Button, IconButton } from 'bso-ui-kit';
import SelectFilter from './components/FormFilter/SelectFilter';
import DateTimeFilter from './components/DateTimeFilter';
import SelectMultipleFilter from './components/FormFilter/SelectMultipleFilter';

const Transition = React.forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />);

/**
  * Render Filters
  * @component
  */
const Filter = ({ filterConfig }) => {
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState();
  const location = useLocation();
  const history = useHistory();
  const search = new URLSearchParams(location.search);

  const getFilterFieldType = (type, props) => {
    if (type === 'select') return <SelectFilter {...props} variant="outlined" />;
    if (type === 'select-multiple') return <SelectMultipleFilter {...props} variant="outlined" />;
    if (type === 'datetime') return <DateTimeFilter {...props} inputVariant="outlined" />;
    return <FormFieldFactory type={type} {...props} variant="outlined" />;
  };

  function handleClickOpen() {
    setOpen(true);
    const filters = {};
    filterConfig.forEach((selectedFilter) => {
      let value;
      if (selectedFilter.type === 'select') {
        // eslint-disable-next-line no-nested-ternary
        value = search.get(selectedFilter.name) !== null
          ? Number.isNaN(parseInt(search.get(selectedFilter.name), 10))
            ? search.get(selectedFilter.name)
            : parseInt(search.get(selectedFilter.name), 10)
          : '';
      } else if (selectedFilter.type === 'select-multiple') {
        value = search.get(selectedFilter.name) || '';
        if (value) {
          const splitValue = value.split(',');
          value = [];
          splitValue.forEach((item) => {
            value.push(selectedFilter.config.selectOptions.find((option) => option.value === item));
          });
        }
      } else if (selectedFilter.type === 'datetime') {
        value = search.get(selectedFilter.name)
          ? moment.utc(search.get(selectedFilter.name)).format('YYYY-MM-DD HH:mm:ss') : '';
      } else {
        value = search.get(selectedFilter.name) !== null
          ? search.get(selectedFilter.name) : '';
      }
      filters[selectedFilter.name] = value;
    });
    setFilter(filters);
  }

  function handleClose() {
    setOpen(false);
  }

  const onChange = (value, filterKey) => {
    setFilter({ ...filter, [filterKey]: value });
  };

  const setValues = (key, value) => {
    if (key === 'method') {
      search.set('method', value.map((item) => item.value).join(','));
    } else if (key === 'date_from') {
      search.set('date_from', moment.utc(value).format('YYYY-MM-DD HH:mm:ss'));
    } else if (key === 'date_to') {
      search.set('date_to', moment.utc(value).format('YYYY-MM-DD HH:mm:ss'));
    } else {
      search.set(key, value);
    }
  };

  const cleanSearch = () => {
    search.set('offset', 0);
    search.set('limit', 10);
    Object.entries(filter).forEach(([key, value]) => {
      if (search.get(key) !== null) {
        if (value === null || value === '' || value === undefined) {
          search.delete(key);
        } else {
          setValues(key, value);
        }
        return;
      }

      if (value === null || value === '') {
        return;
      }

      setValues(key, value);
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (event.currentTarget.id === 'FilterSubmit') {
      cleanSearch();
      history.push({ pathname: location.pathname, search: search.toString() });
    }
    setOpen(false);
  };

  const handleClearAll = () => {
    const filters = {};

    filterConfig.forEach((selectedFilter) => {
      filters[selectedFilter.name] = '';
    });
    setFilter(filters);
  };

  // const handleFilteKeyPress = e => {
  //   const key = e.key || e.keyCode;
  //   if (key.toLowerCase() === 'f' && !open) {
  //     handleClickOpen();
  //   }
  // };

  // useEffect(() =>
  // document.addEventListener('keypress', handleFilteKeyPress);
  //   () => {
  //     document.removeEventListener('keypress', handleFilteKeyPress);
  //   });

  const renderFields = () => (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography id="FilterTitle" variant="h4">Filters_</Typography>
        <span style={{ flex: 1 }} />
        <IconButton id="CloseFilterButton" color="primary" onClick={handleClose}>
          <Close />
        </IconButton>
      </div>
      {filter ? (
        <Paper style={{
          // backgroundColor: theme.palette.background.default,
          overflowX: 'hidden',
          boxShadow: 'none',
          width: 480,
          maxHeight: 600,
          padding: '24px 0px',
        }}
        >
          <List disablePadding>
            <form id="filter" onSubmit={handleSubmit}>
              {filterConfig.map((item, index) => (
                <ListItem key={item.name} disableGutters>
                  {getFilterFieldType(item.type, {
                    key: `filter-${item.name}`,
                    value: filter[item.name],
                    name: item.name,
                    label: item.label,
                    autoFocus: index === 0,
                    onChange,
                    async: item.async,
                    disabled: item.disabled,
                    config: item.config
                  })}
                </ListItem>
              ))}
            </form>
          </List>
        </Paper>
      ) : null}
    </>
  );

  const renderActions = () => (
    <>
      {/* <List disablePadding>
        <span style={{ flex: 1 }} />
        <ListItem disableGutters>
          <Button id="FilterClearButton" variant="outlined" onClick={handleClearAll} fullWidth>
            Clear Filters
          </Button>
        </ListItem>
        <ListItem disableGutters>
          <Button
            id="FilterSubmit"
            variant="contained"
            fullWidth
            onClick={handleSubmit}
            color="primary"
            type="submit" // set the buttom type is submit
            form="filter"
          >
            Apply Filters
          </Button>
        </ListItem>
      </List> */}
      <Button
        id="FilterClearButton"
        variant="cta"
        color="white"
        state="solid"
        onClick={handleClearAll}
        endIcon={<ClearAll />}
      >
        Clear
      </Button>
      <Button
        id="FilterSubmit"
        variant="cta"
        color="primary"
        state="solid"
        endIcon={<ArrowForward />}
        onClick={handleSubmit}
        type="submit" // set the buttom type is submit
        form="filter"
      >
        Apply
      </Button>
    </>
  );

  return (
    <>
      <IconButton id="FilterOpenButton" color="primary" onClick={handleClickOpen}>
        <Tune />
      </IconButton>
      <Hidden mdUp>
        <Dialog id="filter-dialog" onClose={handleClose} open={open} TransitionComponent={Transition}>
          <DialogContent>
            {renderFields()}
          </DialogContent>
          <DialogActions>
            {renderActions()}
          </DialogActions>
        </Dialog>
      </Hidden>

      <Hidden smDown>
        {/* <Drawer
          anchor="right"
          variant="temporary"
          onClose={handleClose}
          open={open}
          ModalProps={{ hideBackdrop: true }}
          classes={{ paper: classes.paper }}
        >
          <div className={classes.container}>
            {renderFields()}
            {renderActions()}
          </div>
        </Drawer> */}
        <Dialog id="filter-dialog" onClose={handleClose} open={open} TransitionComponent={Transition}>
          <DialogContent>
            {renderFields()}

          </DialogContent>
          <DialogActions>
            {renderActions()}
          </DialogActions>
        </Dialog>
      </Hidden>
    </>
  );
};

Filter.propTypes = {
  /**
   * Configuration list for each filter
   */
  filterConfig: PropTypes.array.isRequired,
};

export default Filter;
