import React from 'react';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import API from 'common/api';
import { PlaceHolderText } from 'components/PlaceholderCard/PlaceholderCard';
import Progress from 'components/Progress/Progress';
import { dateTimeFormatter } from 'helpers/formatters';
import useInterface from 'views/Interface/InterfaceContext';
import useList from 'hooks/useList';
import TablePaginator from 'components/TablePaginator/TablePaginator';
import useUser from 'hooks/useUser';
import {
  Card, Typography, FormField, RouterLink
} from 'bso-ui-kit';
import PAGES from 'common/pages';
import { isAppAdmin } from 'helpers/permissions';
import { Skeleton } from '@material-ui/lab';
import InterfaceStatus from 'views/Interface/components/InterfaceStatus';
import InterfaceToolbar from './InterfaceToolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2)
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const InterfacesList = () => {
  const { selectedInterfaces, setSelectedInterfaces } = useInterface();
  const location = useLocation();
  const classes = useStyles();
  const height = '80vh';
  const [{ data: userData }] = useUser();
  const query = {
    storeKey: 'interface-list',
    apiEndpoint: API.INTERFACE,
  };
  const [{
    data, isLoading, isError, refetch, isFetched, isFetching
  }] = useList(query.storeKey, query.apiEndpoint);

  const handleSelectOne = (selectedId) => {
    const selectedIndex = selectedInterfaces.indexOf(selectedId);
    let newSelectedInterfaces = [];

    if (selectedIndex === -1) {
      newSelectedInterfaces = newSelectedInterfaces.concat(selectedInterfaces, selectedId);
    } else if (selectedIndex === 0) {
      newSelectedInterfaces = newSelectedInterfaces.concat(selectedInterfaces.slice(1));
    } else if (selectedIndex === selectedInterfaces.length - 1) {
      newSelectedInterfaces = newSelectedInterfaces.concat(selectedInterfaces.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedInterfaces = newSelectedInterfaces.concat(
        selectedInterfaces.slice(0, selectedIndex),
        selectedInterfaces.slice(selectedIndex + 1)
      );
    }
    setSelectedInterfaces(newSelectedInterfaces);
  };

  const handleSelectAllClick = (value) => {
    if (value.checked) {
      const newSelecteds = data.results.map((n) => n.id);
      setSelectedInterfaces(newSelecteds);
      return;
    }
    setSelectedInterfaces([]);
  };

  if (isError || (isFetched && !data)) {
    return (
      <div style={{ height }}>
        <PlaceHolderText variant="h3" content="Error getting interfaces" height="100%" />
      </div>
    );
  }

  const renderData = () => (
    <Card
      id="InterfacesListCard"
      className={clsx(classes.root)}
    >
      <CardContent className={classes.content}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ flex: 1 }} />
        </div>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {userData && (isAppAdmin(userData.groups) || userData.is_staff) ? (
                    <TableCell>
                      <FormField
                        type="checkbox"
                        color="primary"
                        indeterminate={selectedInterfaces.length > 0 && selectedInterfaces.length < data.results.length}
                        checked={data.results.length && selectedInterfaces.length === data.results.length}
                        onChange={(name, value) => handleSelectAllClick(value)}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                      />
                    </TableCell>
                  ) : null}
                  {userData.is_staff ? (
                    <TableCell><Typography variant="subtitle1">ID</Typography></TableCell>
                  ) : null}
                  <TableCell><Typography variant="subtitle1">Device</Typography></TableCell>
                  <TableCell><Typography variant="subtitle1">Port</Typography></TableCell>
                  <TableCell><Typography variant="subtitle1">Description</Typography></TableCell>
                  <TableCell><Typography variant="subtitle1">Activation date</Typography></TableCell>
                  <TableCell><Typography variant="subtitle1">Termination date</Typography></TableCell>
                  <TableCell>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <Typography variant="subtitle1">
                        Status
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell><Typography variant="subtitle1">Source</Typography></TableCell>
                  <TableCell><Typography variant="subtitle1">SFP Tx</Typography></TableCell>
                  <TableCell><Typography variant="subtitle1">SFP Rx</Typography></TableCell>
                  {userData && (userData.is_staff || isAppAdmin(userData.groups)) ? (
                    <TableCell><Typography variant="subtitle1">Activate</Typography></TableCell>
                  ) : null}
                </TableRow>
              </TableHead>
              {isFetched ? (
                <TableBody>
                  {data.results.map((iface) => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={iface.id}
                    >
                      {userData && (isAppAdmin(userData.groups) || userData.is_staff) ? (
                        <TableCell>
                          <FormField
                            type="checkbox"
                            color="primary"
                            checked={selectedInterfaces.indexOf(iface.id) !== -1}
                            onChange={() => handleSelectOne(iface.id)}
                          />
                        </TableCell>
                      ) : null}
                      {userData.is_staff ? (
                        <TableCell>
                          <RouterLink
                            disableUnderline
                            to={{
                              pathname: `${PAGES.INTERFACE_MGMT}/${iface.id}`,
                              search: location.search
                            }}
                          >
                            <Typography color="primary" variant="body2" component="span">{iface.id}</Typography>
                          </RouterLink>
                        </TableCell>
                      ) : null}
                      <TableCell>
                        <Typography color="primary" variant="body2">{iface.device.hostname}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography color="primary" variant="body2">{iface.name}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography color="primary" variant="body2">
                          {iface.description ? iface.description : '---'}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography color="primary" variant="body2">{iface.activation_date
                          ? dateTimeFormatter(iface.activation_date) : '---'}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography color="primary" variant="body2">{iface.termination_date
                          ? dateTimeFormatter(iface.termination_date) : '---'}
                        </Typography>
                      </TableCell>
                      <InterfaceStatus interfaceId={iface.id} name={iface.name} />
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  {[...Array(10)].map((item, index) => (
                    <TableRow
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                    >
                      <TableCell><FormField type="checkbox" disabled /></TableCell>
                      <TableCell><Skeleton /></TableCell>
                      <TableCell><Skeleton /></TableCell>
                      <TableCell><Skeleton /></TableCell>
                      <TableCell><Skeleton /></TableCell>
                      <TableCell><Skeleton /></TableCell>
                      <TableCell><Skeleton /></TableCell>
                      <TableCell><Skeleton /></TableCell>
                      <TableCell><Skeleton /></TableCell>
                      <TableCell><Skeleton /></TableCell>
                      <TableCell><Skeleton width={64} /></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}

            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePaginator
          count={data.count}
          previous={data.previous}
          next={data.next}
          isFetched={isFetched}
        />
      </CardActions>
    </Card>
  );

  if (userData && (userData.is_staff || isAppAdmin(userData.groups))) {
    return (
      <Box p={3}>
        <InterfaceToolbar
          title="Interfaces"
          isFetching={isFetching}
          refetch={refetch}
          data={data}
          userData={userData}
          selectedInterfaces={selectedInterfaces}
          setSelectedInterfaces={setSelectedInterfaces}
        />
        {!isLoading
          ? renderData()
          : <Progress />}
      </Box>
    );
  }

  return <PlaceHolderText content="No permissions to view this page" height={height} />;
};

export default InterfacesList;
