import React from 'react';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import Tooltip from '@material-ui/core/Tooltip';
import { useHistory, useLocation } from 'react-router-dom';
import { getParamsStrings, removeDuplicateURLSearchParams } from 'helpers/url';
import { IconButton } from 'bso-ui-kit';

const NextPaginator = ({ previous, isFetched }) => {
  const history = useHistory();
  const location = useLocation();
  // const isFetching = useIsFetching();

  const handleNextButtonClick = () => {
    const params = new URLSearchParams(getParamsStrings(previous));
    const newParams = removeDuplicateURLSearchParams(params);
    history.push({ pathname: location.pathname, search: newParams.toString() });
  };

  return (
    <Tooltip title="Previous">
      <div>
        <IconButton
          color="primary"
          onClick={handleNextButtonClick}
          disabled={!previous || !isFetched}
        >
          <KeyboardArrowLeft />
        </IconButton>
      </div>
    </Tooltip>
  );
};

export default NextPaginator;
