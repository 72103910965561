/* eslint-disable arrow-parens */
import React from 'react';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';

const FormNumberField = ({
  id,
  key,
  name,
  label,
  helperText,
  placeholder,
  required,
  readOnly,
  disabled,
  autoFocus = false,
  error,
  value,
  onChange,
  type,
  allowNegative
}) => {
  const handleChange = event => {
    onChange(event.target.value, name);
  };
  let decimalSeparator = null;
  if (type === 'float') {
    decimalSeparator = '.';
  }

  return (
    <NoSsr>
      <NumberFormat
        customInput={TextField}
        decimalSeparator={decimalSeparator}
        id={id}
        key={key}
        name={name}
        label={label}
        autoFocus={autoFocus}
        helperText={helperText}
        placeholder={placeholder}
        fullWidth
        value={value}
        required={required}
        readOnly={readOnly}
        disabled={disabled}
        onChange={handleChange}
        error={error}
        allowNegative={allowNegative}
        inputProps={{ readOnly }}
      />
    </NoSsr>
  );
};

export default FormNumberField;
