import React from 'react';
import {
  Box,
  Grid,
  Card,
  CardContent
} from '@material-ui/core';
import Progress from 'components/Progress/Progress';
import { Typography } from 'bso-ui-kit';
import { getDeviceFeature } from 'helpers/misc';
import DRFTable from 'components/DRFTable/DRFTable';
import DRFRow from 'components/DRFTable/DRFRow';
import DRFCell from 'components/DRFTable/DRFCell';

const ShareInfo = ({ data }) => {
  const renderData = () => (
    <Box px={3}>
      <Typography variant="h5">General</Typography>
      <Grid container spacing={3} alignItems="stretch">
        <Grid item lg={12} xs={12}>
          <Card>
            <CardContent style={{ paddingTop: 0, overflowY: 'auto' }}>
              <DRFTable>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Interface ID</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data?.id}</Typography>
                  </DRFCell>
                </DRFRow>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Port</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data?.name}</Typography>
                  </DRFCell>
                </DRFRow>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Description</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data?.description ? data.description : '---'}</Typography>
                  </DRFCell>
                </DRFRow>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Type</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data?.type?.name ? data.type.name : '---'}</Typography>
                  </DRFCell>
                </DRFRow>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Device Name</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data?.device?.hostname}</Typography>
                  </DRFCell>
                </DRFRow>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Device Feed</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data?.device?.feed?.name}</Typography>
                  </DRFCell>
                </DRFRow>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Device Feature</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data?.device ? getDeviceFeature(data?.device) : '---'}</Typography>
                  </DRFCell>
                </DRFRow>
              </DRFTable>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <>
      {
        data ? renderData()
          : <Progress />
      }
    </>
  );
};

export default ShareInfo;
