import { Typography } from 'bso-ui-kit';
import React from 'react';

const PaginatorLabel = ({ count, limit, offset }) => {
  const page = Math.floor(offset / limit) + 1 || 0;

  const isLastPage = () => page >= Math.ceil(count / limit);

  let lowerLimit = 1;
  let upperLimit = count;
  if (page === 1) {
    if (parseInt(offset, 10) + limit > count) {
      lowerLimit = parseInt(offset, 10) + 1;
    }
    upperLimit = limit;
    if (count < limit) {
      upperLimit = count;
    }
  } else if (isLastPage()) {
    lowerLimit = parseInt(offset, 10) + 1;
  } else {
    lowerLimit = parseInt(offset, 10) + 1;
    upperLimit = lowerLimit + limit - 1;
  }

  return <Typography variant="body2">{`${lowerLimit} - ${upperLimit} of ${count}`}</Typography>;
};

export default PaginatorLabel;
