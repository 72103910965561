import React from 'react';
import moment from 'moment';
import {
  Toolbar, Typography,
} from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import API from 'common/api';
import { get } from 'helpers/query/fetchers';
import { Button } from 'bso-ui-kit';
import LogsFilter from './LogsFilter';

const LogsToolbar = ({ title, con, children }) => {
  const location = useLocation();

  const handleDownload = () => {
    const urlParams = new URLSearchParams(location.search);
    urlParams.set('export', 'csv');

    const url = API.LOGS;

    get(url, urlParams, {
      responseType: 'blob' // important
    })
      .then((response) => {
        const blob = new Blob([response.data], { type: response.data.type });
        const windowURL = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = windowURL;
        const fileName = `Logs_${moment().format('MMDDYYYY')}.csv`;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Error', error);
      });
  };

  return (
    <Toolbar
      style={{
        display: 'flex', minHeight: 48, alignItems: 'center', marginBottom: 32
      }}
      disableGutters
    >
      <div>
        <Typography id="HeaderTitle" variant="h2" color="primary">{title}_</Typography>
      </div>
      <span style={{ flex: 1 }} />
      <Button
        id="DownloadCSVButton"
        startIcon={<CloudDownload />}
        color="primary"
        variant="standard"
        state="outlined"
        onClick={handleDownload}
        style={{ marginRight: 8 }}
      >
        Download CSV
      </Button>
      {children}
      <LogsFilter con={con} />
    </Toolbar>
  );
};

export default LogsToolbar;
