import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from '@material-ui/core';
import { Add, Edit, Delete } from '@material-ui/icons';
import API from 'common/api';
import useConnectErrorHandler from 'helpers/errorHandlers';
import { PlaceHolderText } from 'components/PlaceholderCard/PlaceholderCard';
import Progress from 'components/Progress/Progress';
import { delWithBody } from 'helpers/query/fetchers';
import { useSnackbar } from 'notistack';
import SnackMessage from 'components/SnackMessage/SnackMessage';
import DeleteConfirmationDialog from 'components/DeleteConfirmationDialog/DeleteConfirmationDialog';
import useUser from 'hooks/useUser';
import {
  IconButton,
  Card,
  Typography
} from 'bso-ui-kit';
import { isAppAdmin } from 'helpers/permissions';
import InterfaceConfigurationCreate from './Form/InterfaceConfigurationCreate';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2)
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  tableCell: {
    padding: '0px 16px'
  }
}));

const InterfaceCompressions = ({ compression, uncompressedMac, refetch = null }) => {
  const { iid } = useParams();
  const classes = useStyles();
  const minHeight = '20vh';
  const height = '40vh';
  const [{ data: userData }] = useUser();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { handleError } = useConnectErrorHandler();

  const noData = () => (
    <TableBody>
      <TableRow>
        <TableCell align="center" colSpan={4}>
          <Typography color="primary" variant="caption">NO DATA</Typography>
        </TableCell>
      </TableRow>
    </TableBody>
  );

  const deleteCompression = (item) => {
    setLoading(true);
    const url = `${API.INTERFACE_COMPRESSION_DYNAMIC(iid)}/del/`;
    const payload = { ...item, type: 'compression' };
    delWithBody(url, payload)
      .then(() => {
        const msg = `${payload.type} ID: ${payload.id} has been removed from the switch.`;
        refetch();

        enqueueSnackbar(msg, {
          content: (key) => <SnackMessage id={key} message={msg} variant="success" />
        });
      })
      .catch((error) => {
        setLoading(false);
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getCompressionActionButtons = (i) => {
    let item;
    if (compression.map((x) => x.id).includes(i + 1)) {
      item = compression[i];
    }

    return (
      <Box display="flex" alignItems="center">
        {compression && (userData.is_staff || isAppAdmin(userData.groups)) ? (
          <>
            <InterfaceConfigurationCreate
              title={`${item ? 'Update' : 'Add'} Compression`}
              iconButton={{
                content: item ? <Edit /> : <Add />,
                color: 'primary',
                size: 'small',
                customSize: true
                // disabled: isFetching
              }}
              button={{
                content: item ? 'Update' : 'Add',
                color: 'primary',
                variant: 'standard',
                state: 'outlined',
                customSize: true
              }}
              data={{ ...item, type: 'compression' }}
              refetch={refetch}
              prefilledId={i + 1}
            />
            <span style={{ flex: 0.1 }} />
            {item ? (
              <DeleteConfirmationDialog
                title="Delete compression"
                contentText="Are you sure you want to delete this compression?"
                confirmText="Yes"
              >
                {(confirm) => (
                  <Tooltip title="Delete Compression">
                    <div>
                      <IconButton
                        size="small"
                        color="primary"
                        bgTransparent
                        // disabled={isFetching}
                        style={{
                          maxWidth: '20px', maxHeight: '20px', minWidth: '20px', minHeight: '20px'
                        }}
                        onClick={confirm(() => deleteCompression(item))}
                      >
                        <Delete />
                      </IconButton>
                    </div>
                  </Tooltip>
                )}
              </DeleteConfirmationDialog>
            ) : null}
          </>
        ) : (
          <div style={{ paddingTop: '36px' }} />
        )}
      </Box>
    );
  };

  const renderData = () => (
    <Grid container spacing={4} alignItems="stretch">
      <Grid item lg={12} xs={12}>
        <Typography variant="h5">Compression</Typography>
        <Card style={{ minHeight }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ flex: 1 }} />
          </div>
          <PerfectScrollbar>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>
                    <Typography variant="subtitle1">ID</Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography variant="subtitle1">MAC source</Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography variant="subtitle1">MAC destination</Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography variant="subtitle1">Action</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {[...Array(16)].map((_, i) => (
                  <TableRow
                    hover
                    // eslint-disable-next-line react/no-array-index-key
                    key={`compression${i + 1}`}
                  >
                    <TableCell className={classes.tableCell}>
                      <Typography color="primary" variant="body2" component="span">
                        {i + 1}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Typography color="primary" variant="body2">
                        {compression.map((x) => x.id).includes(i + 1) ? compression[i]?.mac_src : '---'}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Typography color="primary" variant="body2">
                        {compression.map((x) => x.id).includes(i + 1) ? compression[i]?.mac_dst : '---'}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {getCompressionActionButtons(i)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
        </Card>
      </Grid>
      <Grid item lg={12} xs={12}>
        <Typography variant="h5">Uncompressed MAC</Typography>
        <Card style={{ minHeight }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ flex: 1 }} />
          </div>
          <PerfectScrollbar>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>
                    <Typography variant="subtitle1">MAC source</Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography variant="subtitle1">MAC destination</Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography variant="subtitle1">Action</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              {uncompressedMac && uncompressedMac.length > 0
                ? (
                  <TableBody>
                    {uncompressedMac.map((item, index) => (
                      <TableRow
                        hover
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                      >
                        <TableCell className={classes.tableCell}>
                          <Typography color="primary" variant="body2">{item.mac_src}</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <Typography color="primary" variant="body2">{item.mac_dst}</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {(userData.is_staff || isAppAdmin(userData.groups)) ? (
                            <InterfaceConfigurationCreate
                              title="Add to Compression"
                              button={{
                                content: 'Add',
                                color: 'primary',
                                variant: 'standard',
                                state: 'outlined',
                                disabled: compression.length >= 16,
                                customSize: true
                              }}
                              data={{ ...item, type: 'compression' }}
                              refetch={refetch}
                            />
                          ) : null}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )
                : noData()}
            </Table>
          </PerfectScrollbar>
        </Card>
      </Grid>
    </Grid>
  );

  if (userData && (userData.is_staff || isAppAdmin(userData.groups))) {
    return (compression && !loading) ? renderData() : <Progress />;
  }

  return <PlaceHolderText content="No permissions to view this page" height={height} />;
};

export default InterfaceCompressions;
