import moment from 'moment';

// const humanizeDuration = require('humanize-duration');

export const timestampToDate = (timestamp, format = 'DD MMM YYYY') => moment(timestamp, 'X').format(format);
export const getNameInitials = (name) => {
  const splittedNames = name.split(' ');

  if (splittedNames.length > 1) {
    const firstInitial = splittedNames[0][0];
    const lastInitial = splittedNames[1][0];

    return firstInitial + lastInitial;
  }

  return null;
};

export const dateTimeFormatter = (dateTimeToFormat, format = 'MMM DD, YYYY HH:mm UTC') => {
  if (dateTimeToFormat) {
    return moment(dateTimeToFormat)
      .utc()
      .format(format);
  }
  return '---';
};

// export const durationFormatter = (durationToFormat) => {
//   if (durationToFormat) return humanizeDuration(durationToFormat * 1000); // convert to millisecond.
//   return '---';
// };

export const normalise = (value, MIN = 0, MAX) => ((value - MIN) * 100) / (MAX - MIN);

export const capitalizeFirstLetter = ([firstLetter, ...restOfWord]) => (
  firstLetter.toUpperCase() + restOfWord.join('')
);

export default {
  timestampToDate, dateTimeFormatter, normalise, capitalizeFirstLetter
};
