import React, { useEffect, useState } from 'react';
import {
  useLocation, Redirect, NavLink, useParams
} from 'react-router-dom';
import axios from 'axios';
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  Fade,
  Box,
} from '@material-ui/core';
import PAGES from 'common/pages';
import { ArrowForward } from '@material-ui/icons';
import { REACT_APP_PORTAL_API } from 'config';
import API from 'common/api';
import { useSnackbar } from 'notistack';
import SnackMessage from 'components/SnackMessage/SnackMessage';
import LinearLoading from 'common/LinearLoading/LinearLoading';
import useAuth from 'hooks/useAuth';
import { Button, Card } from 'bso-ui-kit';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    padding: theme.spacing(3)
  },
  title: {
    textAlign: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  titleError: {
    textAlign: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
    color: theme.palette.error.main
  },
  subtitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(3),
    // paddingLeft: theme.spacing(3),
    // paddingRight: theme.spacing(3)
  },
}));

const ConfirmSignUp = () => {
  const classes = useStyles();
  const location = useLocation();
  const { isSignedIn } = useAuth();
  const [loading, setLoading] = useState(false);
  const { from } = location.state || { from: { pathname: '/' } };
  const { code } = useParams();
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setLoading(true);
    const url = `${REACT_APP_PORTAL_API}${API.CONFIRM_SIGN_UP}`;
    axios
      .post(
        url,
        { code }
      )
      .then(() => {
        setLoading(false);
        setIsSubmitSuccess(true);
      })
      .catch((err) => {
        setLoading(false);
        setIsSubmitSuccess(false);
        if (err.response && err.response.data.code) {
          enqueueSnackbar(err.response.data.code, {
            content: (key) => (
              <SnackMessage
                data-testid="error-msg"
                id={key}
                message={err.response.data.code}
                variant="error"
              />
            )
          });
        } else {
          enqueueSnackbar(err.toString(), {
            content: (key) => (
              <SnackMessage
                data-testid="error-msg"
                id={key}
                message={err.toString()}
                variant="error"
              />
            )
          });
        }
      });
  }, [code, enqueueSnackbar]);

  if (isSignedIn()) {
    return <Redirect to={from} />;
  }

  if (isSubmitSuccess) {
    return (
      <div>
        <Fade
          in
          mountOnEnter
          timeout={400}
          unmountOnExit
        >
          <Card color="gradient">
            <Box p={3}>
              <Card color="white">
                <Box p={3}>
                  <Typography
                    className={classes.title}
                    data-testid="welcome-message"
                    variant="h3"
                  >
                    Thank you for signing up.
                  </Typography>
                  <Box display="flex" justifyContent="center" marginTop={5}>
                    <NavLink to={{ pathname: PAGES.SIGN_IN, state: { slideTransition: 'left' } }}>
                      <Button
                        endIcon={<ArrowForward />}
                        variant="cta"
                        state="outlined"
                        color="primary"
                      >
                        Sign in
                      </Button>
                    </NavLink>
                  </Box>
                </Box>
              </Card>
            </Box>
          </Card>
        </Fade>
      </div>
    );
  }

  return (
    <div>
      {loading ? (
        <LinearLoading title=" Please wait while we confirm your account..." />
      )
        : (
          <Fade
            in
            mountOnEnter
            timeout={400}
            unmountOnExit
          >
            <Card color="gradient">
              <Box p={3}>
                <Card color="white">
                  <Box p={3}>
                    <Typography
                      className={classes.titleError}
                      variant="h3"
                    >
                      There is a problem confirming your account.
                    </Typography>
                    <Box marginTop={2}>
                      <Typography variant="subtitle1" style={{ textAlign: 'center' }}>
                        Please get a new confirmation link using the link we sent to your email.
                      </Typography>
                    </Box>
                    <Box marginTop={2}>
                      <Typography variant="body1" style={{ textAlign: 'center' }}>
                        If problem persists, contact devops@bso.co
                      </Typography>
                    </Box>
                  </Box>
                </Card>
              </Box>
            </Card>
          </Fade>
        )}
    </div>
  );
};

export default ConfirmSignUp;
