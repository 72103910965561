import React, { useState } from 'react';
import {
  useLocation, Redirect, NavLink
} from 'react-router-dom';
import axios from 'axios';
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  List,
  ListItem,
  CircularProgress,
  Fade,
  Box,
  Grid,
  Hidden,
} from '@material-ui/core';
import PAGES from 'common/pages';
import { REACT_APP_PORTAL_API } from 'config';
import API from 'common/api';
import SnackMessage from 'components/SnackMessage/SnackMessage';
import { useSnackbar } from 'notistack';
import { ArrowForward, ArrowBack } from '@material-ui/icons';
import TransitionHOC from 'components/TransitionHOC/TransitionHOC';
import {
  Button, Card, FormField, IconButton, RouterLink
} from 'bso-ui-kit';
import useAuth from '../../hooks/useAuth';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    padding: theme.spacing(3)
  },
  title: {
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  subtitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },

    paddingBottom: theme.spacing(3),
    // paddingLeft: theme.spacing(3),
    // paddingRight: theme.spacing(3)
  },
  submitButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  }
}));

const initialFormState = {
  email: '',
  first_name: '',
  last_name: '',
  password: '',
  confirm_password: '',
};

const initialFormErrorState = {
  email: '',
  first_name: '',
  last_name: '',
  password: '',
  confirm_password: '',
};

const SignUp = () => {
  const classes = useStyles();
  const location = useLocation();
  const { isSignedIn } = useAuth();
  const { from } = location.state || { from: { pathname: '/' } };
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [formState, setFormState] = useState(initialFormState);
  const [formErrorState, setFormErrorState] = useState(initialFormErrorState);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);

  const handleChange = (name, value) => {
    setFormState({ ...formState, [name]: value });
  };

  const isError = (error) => Boolean(error);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    const url = `${REACT_APP_PORTAL_API}${API.REGISTER}`;
    axios
      .post(
        url,
        { ...formState }
      )
      .then(() => {
        setLoading(false);
        setIsSubmitSuccess(true);
      })
      .catch((err) => {
        setLoading(false);
        setIsSubmitSuccess(false);
        setFormErrorState(err.response.data);
        if (err.response.data.error) {
          enqueueSnackbar(err.response.data.error, {
            content: (key) => (
              <SnackMessage
                data-testid="error-msg"
                id={key}
                message={err.response.data.error}
                variant="error"
              />
            )
          });
        }
      });
  };

  if (isSignedIn()) {
    return <Redirect to={from} />;
  }

  if (isSubmitSuccess) {
    return (
      <div>
        <Fade
          in
          mountOnEnter
          timeout={400}
          unmountOnExit
        >
          <Card color="gradient">
            <Box p={3}>
              <Card color="white">
                <div
                  className={classes.form}
                >
                  <Typography
                    style={{ marginTop: 16, textAlign: 'center', marginBottom: 16 }}
                    data-testid="welcome-message"
                    variant="h3"
                  >
                    A confirmation link has been sent to your email.
                  </Typography>
                  <div className={classes.subtitle}>
                    <Typography
                      variant="subtitle1"
                    >
                      To complete sign up, please confirm using the link we sent to your email.
                    </Typography>

                  </div>
                  <div className={classes.subtitle}>
                    <NavLink to={{ pathname: PAGES.SIGN_IN, state: { slideTransition: 'left' } }}>
                      <Button
                        endIcon={<ArrowForward />}
                        variant="cta"
                        state="outlined"
                        color="primary"
                      >
                        Sign in
                      </Button>
                    </NavLink>
                  </div>
                </div>
              </Card>
            </Box>
          </Card>
        </Fade>
      </div>

    );
  }
  return (
    <div>
      <TransitionHOC
        in
        mountOnEnter
        timeout={400}
        unmountOnExit
      >
        <Box>
          <Card color="gradient">
            <Box padding={2}>
              <Grid container>
                <Hidden smDown>
                  <Grid item md={6}>
                    <Box display="flex" flexDirection="column" alignItems="flex-start" paddingBottom={4}>
                      <Typography
                        className={classes.title}
                        data-testid="welcome-message"
                        variant="h1"
                      >
                        Join our
                      </Typography>
                      <Typography
                        data-testid="welcome-message"
                        variant="h1"
                      >
                        portal_
                      </Typography>
                    </Box>
                  </Grid>
                </Hidden>
                <Grid item md={6} xs={12}>
                  <Card color="white">
                    <form
                      className={classes.form}
                      onSubmit={handleSubmit}
                    >
                      <Box display="flex">
                        <Box>
                          <RouterLink to={{ pathname: PAGES.SIGN_IN, state: { slideTransition: 'left' } }}>
                            <IconButton color="primary">
                              <Box>
                                <ArrowBack />
                              </Box>
                            </IconButton>
                          </RouterLink>
                        </Box>
                        <Box marginTop={0.7}>
                          <RouterLink to={{ pathname: PAGES.SIGN_IN, state: { slideTransition: 'left' } }}>
                            <Typography variant="button" color="primary" component="span">
                              Back to sign in
                            </Typography>
                          </RouterLink>
                        </Box>
                      </Box>
                      <Typography
                        className={classes.title}
                        data-testid="welcome-message"
                        variant="h3"
                      >
                        Sign up
                      </Typography>
                      <div className={classes.subtitle} />
                      <List disablePadding>
                        <ListItem disableGutters>
                          <FormField
                            data-testid="first_name-input"
                            label="First name"
                            name="first_name"
                            onChange={handleChange}
                            type="string"
                            variant="outlined"
                            fullWidth
                            value={formState.first_name}
                            error={isError(formErrorState.first_name)}
                            helperText={formErrorState.first_name ? formErrorState.first_name : null}
                          />
                        </ListItem>
                        <ListItem disableGutters>
                          <FormField
                            data-testid="last_name-input"
                            label="Last name"
                            name="last_name"
                            onChange={handleChange}
                            type="string"
                            variant="outlined"
                            fullWidth
                            value={formState.last_name}
                            error={isError(formErrorState.last_name)}
                            helperText={formErrorState.last_name ? formErrorState.last_name : null}
                          />
                        </ListItem>
                        <ListItem disableGutters>
                          <FormField
                            data-testid="email-input"
                            label="Email address"
                            name="email"
                            onChange={handleChange}
                            type="string"
                            variant="outlined"
                            fullWidth
                            value={formState.email}
                            error={isError(formErrorState.email)}
                            helperText={formErrorState.email ? formErrorState.email : null}
                          />
                        </ListItem>
                        <ListItem disableGutters>
                          <FormField
                            data-testid="password-input"
                            label="Password"
                            name="password"
                            onChange={handleChange}
                            type="password"
                            variant="outlined"
                            fullWidth
                            value={formState.password}
                            error={isError(formErrorState.password)}
                            helperText={formErrorState.password ? formErrorState.password : null}
                          />
                        </ListItem>
                        <ListItem disableGutters>
                          <FormField
                            data-testid="confirm-password-input"
                            label="Confirm password"
                            name="confirm_password"
                            onChange={handleChange}
                            type="password"
                            variant="outlined"
                            fullWidth
                            value={formState.confirm_password}
                            error={isError(formErrorState.confirm_password)}
                            helperText={formErrorState.confirm_password ? formErrorState.confirm_password : null}
                          />
                        </ListItem>
                        <Typography variant="body2" style={{ marginTop: 16 }} color="primary">
                          By signing up, I agree to the Terms of use and
                          acknowledge that I read the Privacy policy and Cookie policy.
                        </Typography>
                      </List>
                      <Box marginTop={3}>
                        <Button
                          className={classes.submitButton}
                          data-testid="SignUp-button"
                          disabled={!formState.email || !formState.first_name
                            || !formState.last_name || !formState.password
                            || !formState.confirm_password || loading}
                          endIcon={!loading ? <ArrowForward /> : <CircularProgress size={20} />}
                          type="submit"
                          variant="cta"
                          state="outlined"
                          color="primary"
                        >
                          Sign up now
                        </Button>
                      </Box>
                    </form>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Box>
      </TransitionHOC>
    </div>
  );
};

export default SignUp;
