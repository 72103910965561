import React, { forwardRef } from 'react';
import {
  NavLink as RouterLink,
  Switch,
  useLocation
} from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  List, ListItem, Drawer, ListItemText, ListSubheader, Box
} from '@material-ui/core';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import PAGES from 'common/pages';
import PrivateRouteWithLayout from 'components/RouteWithLayout/PrivateRouteWithLayout';
import useUser from 'hooks/useUser';
import { isAppAdmin } from 'helpers/permissions';
import Logs from './Logs/Logs';
import Account from './Account/Account';
import UsersView from './User/UsersView';
import UsersDetailView from './User/UsersDetailView';
import InterfacesView from './Interface/InterfacesView';
import InterfacesDetailView from './Interface/InterfacesDetailView';
import DevicesView from './Device/DevicesView';
import DevicesDetailView from './Device/DevicesDetailView';
import DefaultThresholdView from './Device/DefaultThreshold/DefaultThresholdView';

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    zIndex: 1000
  },
  drawerContainer: {
    paddingTop: 100,
    overflow: 'auto',
    height: '100%'
  },
  listItemRoot: {
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
  },
  selected: {
    '&$selected': {
      borderLeft: `4px solid ${theme.palette.primary.main}`,
    }
  },
  content: {
    paddingTop: 16,
    minWidth: 1000,
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth})px`
  }
}));

const CustomRouterRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ width: '100%' }}
  >
    <RouterLink {...props} />
  </div>
));

const Settings = () => {
  const classes = useStyles();
  const location = useLocation();
  const [{ data: userData, isLoading }] = useUser();
  const isSelected = (selectedPage) => {
    const path = location.pathname.split('/');
    if (path.length > 3) {
      path.pop();
      return selectedPage === path.join('/');
    }
    return selectedPage === location.pathname;
  };

  return (
    <>
      <Drawer
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerContainer}>
          <List>
            <ListItem
              button
              component={CustomRouterRouterLink}
              to={{
                pathname: PAGES.ACCOUNT,
              }}
              selected={isSelected(PAGES.ACCOUNT)}
              classes={{
                root: classes.listItemRoot,
                selected: classes.selected
              }}
            >
              <ListItemText primary="Account profile" />
            </ListItem>
          </List>
          {!isLoading && userData
            && (isAppAdmin(userData.groups) || userData.is_staff)
            ? (
              <List subheader={<ListSubheader disableSticky>Administration</ListSubheader>}>
                <ListItem
                  button
                  component={CustomRouterRouterLink}
                  to={{
                    pathname: PAGES.INTERFACE_MGMT,
                  }}
                  selected={isSelected(PAGES.INTERFACE_MGMT)}
                  classes={{
                    root: classes.listItemRoot,
                    selected: classes.selected
                  }}
                >
                  <ListItemText primary="Interface management" />
                </ListItem>
                <ListItem
                  button
                  component={CustomRouterRouterLink}
                  to={{
                    pathname: PAGES.USER_MGMT,
                  }}
                  selected={isSelected(PAGES.USER_MGMT)}
                  classes={{
                    root: classes.listItemRoot,
                    selected: classes.selected
                  }}
                >
                  <ListItemText primary="User management" />
                </ListItem>
                <ListItem
                  button
                  component={CustomRouterRouterLink}
                  to={{
                    pathname: PAGES.DEVICE_MGMT,
                  }}
                  selected={isSelected(PAGES.DEVICE_MGMT) || isSelected(PAGES.DEFAULT_THRESHOLD_MGMT)}
                  classes={{
                    root: classes.listItemRoot,
                    selected: classes.selected
                  }}
                >
                  <ListItemText primary="Device management" />
                </ListItem>
                <ListItem
                  button
                  component={CustomRouterRouterLink}
                  to={{
                    pathname: PAGES.LOGS,
                  }}
                  selected={isSelected(PAGES.LOGS)}
                  classes={{
                    root: classes.listItemRoot,
                    selected: classes.selected
                  }}
                >
                  <ListItemText primary="Logs" />
                </ListItem>
              </List>
            )
            : null}
        </div>
      </Drawer>
      <div className={classes.content}>
        <Switch>
          <Redirect
            exact
            from={PAGES.SETTINGS}
            to={PAGES.ACCOUNT}
          />
          <PrivateRouteWithLayout
            title="Account Profile"
            component={Account}
            exact
            layout={Box}
            path={PAGES.ACCOUNT}
          />
          <PrivateRouteWithLayout
            component={InterfacesView}
            exact
            layout={Box}
            path={PAGES.INTERFACE_MGMT}
          />
          <PrivateRouteWithLayout
            component={InterfacesDetailView}
            exact
            layout={Box}
            path={PAGES.INTERFACE_DETAIL}
          />
          <PrivateRouteWithLayout
            component={UsersView}
            exact
            layout={Box}
            path={PAGES.USER_MGMT}
          />
          <PrivateRouteWithLayout
            component={UsersDetailView}
            exact
            layout={Box}
            path={PAGES.USER_DETAIL}
          />
          <PrivateRouteWithLayout
            component={DevicesView}
            exact
            layout={Box}
            path={PAGES.DEVICE_MGMT}
          />
          <PrivateRouteWithLayout
            component={DevicesDetailView}
            exact
            layout={Box}
            path={PAGES.DEVICE_DETAIL}
          />
          <PrivateRouteWithLayout
            component={DefaultThresholdView}
            exact
            layout={Box}
            path={PAGES.DEFAULT_THRESHOLD_MGMT}
          />
          <PrivateRouteWithLayout
            component={Logs}
            exact
            layout={Box}
            path={PAGES.LOGS}
          />
          <Redirect to={PAGES.NOT_FOUND} />
        </Switch>
      </div>
    </>
  );
};

export default Settings;
