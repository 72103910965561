import React, { useEffect, useState } from 'react';
import axios from 'axios';
import L from 'leaflet';
import {
  MapContainer,
  TileLayer,
  GeoJSON,
  useMap
} from 'react-leaflet';
import 'leaflet-fullscreen/dist/Leaflet.fullscreen';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';

import tileLayer from '../../util/tileLayer';
import './App.scss';

const api = process.env.REACT_APP_PORTAL_API;
const center = [43.817, -79.338];
const zoom = 10;
const labels = ['With Errors', 'Ok with warnings', 'Ok'];
const colors = ['ff0000', 'FFA500', '00FF00'];
const MINUTE_MS = 30000; // fetch links and geojson every 30 seconds
const geojsonMarkerOptions = (color) => (
  {
    radius: 8,
    fillColor: color,
    color,
    weight: 2,
    opacity: 1,
    fillOpacity: 0.8
  }
);
function style(feature) {
  return {
    color: feature.properties.style.color,
    weight: 2,
    opacity: 1,
    fillColor: feature.properties.style.color
  };
}
function pointToLayer(feature, latlng) {
  return L.circleMarker(latlng, geojsonMarkerOptions(feature.properties.style.color));
}
function getVoivodeshipName(feature, layer) {
  let name = feature.properties.site;
  if (feature.geometry.type === 'LineString') {
    name = `${feature.properties.site_a} - ${feature.properties.site_z}`;
  }
  const pops = `<ul style="list-style-type:none;">
                  <li>Name: ${name}</li>
                  <li>Errors: ${feature.properties.errors}</li>
                  <li>Warnings: ${feature.properties.warnings}</li>
                  <li>Unknowns: ${feature.properties.unknowns}</li>
                  <li>Details:
                    <a href="${feature.properties.details}" target="blank">${feature.properties.details}</a>
                  </li>
                </ul>
                `;
  layer.bindPopup(pops);
}
function onEachFeature(feature, layer) {
  const fea = feature;
  layer.on('mouseover', () => {
    getVoivodeshipName(feature, layer);
    this.openPopup();
  });
  layer.on('click', () => {
    window.open(fea.properties.details);
  });
}

const Legend = () => {
  const map = useMap();
  useEffect(() => {
    if (!map) return;
    const legend = new L.Control({ position: 'bottomright' });
    const rows = [];
    legend.onAdd = () => {
      const div = L.DomUtil.create('div', 'legend');
      colors.map((color, index) => (
        rows.push(`
            <div class='row'>
              <i style='background: #${color}'></i>${labels[index]}
            </div>
          `)
      ));
      div.innerHTML = rows.join('');
      return div;
    };

    legend.addTo(map);
  }, [map]);

  return null;
};
async function getLinks() {
  const token = localStorage.getItem('token');
  const headers = {
    Authorization: `Bearer ${token}`
  };
  const params = {
    backbone: 'toronto'
  };
  const response = await axios.get(`${api}/site/links/`, { headers, params });
  return response.data;
}
async function getGeojson() {
  const token = localStorage.getItem('token');
  const headers = {
    Authorization: `Bearer ${token}`
  };
  const params = {
    backbone: 'toronto'
  };
  const response = await axios.get(`${api}/site/backbone/geojson/`, { headers, params });
  return response.data;
}
const NetworkMap = () => {
  const [markers, setMarkers] = useState(null);
  const [geojson, setGeojson] = useState(null);
  useEffect(() => {
    Promise.all([getGeojson(), getLinks()])
      .then((responses) => {
        const [response1, response2] = responses;
        setGeojson(response1);
        setMarkers(response2);
      });
    const interval = setInterval(() => {
      getGeojson().then((data) => {
        setGeojson(data);
      })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err.toString());
        });
      getLinks().then((data) => {
        setMarkers(data);
      })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err.toString());
        });
    }, MINUTE_MS);
    return () => clearInterval(interval); // prevent memory leaks.
  }, []);
  return (
    <MapContainer
      center={center}
      zoom={zoom}
      scrollWheelZoom
      fullscreenControl
    >
      <Legend />
      <TileLayer {...tileLayer} />
      {
        markers ? (
          <GeoJSON
            pointToLayer={pointToLayer}
            data={markers}
            onEachFeature={onEachFeature}
            style={style}
          />
        ) : null
      }
      {
        geojson ? (
          <GeoJSON
            pointToLayer={pointToLayer}
            data={geojson}
            onEachFeature={onEachFeature}
          />
        ) : null
      }
    </MapContainer>
  );
};

export default NetworkMap;
