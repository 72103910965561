import React from 'react';
import { Grid } from '@material-ui/core';

const DRFTable = ({ children }) => (
  <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
    {children}
  </Grid>
);

export default DRFTable;
