import axios from 'axios';
import { REACT_APP_PORTAL_API } from 'config';

const apiClient = () => axios.create({
  baseURL: REACT_APP_PORTAL_API,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`
  }
});

export const signinPost = (url, data) => {
  const source = axios.CancelToken.source();
  const promise = axios.post(`${REACT_APP_PORTAL_API}${url}`, data, { cancelToken: source.token });

  promise.cancel = () => {
    source.cancel('Query was cancelled');
  };

  return promise;
};

export const post = (url, data) => {
  const source = axios.CancelToken.source();
  const promise = apiClient().post(url, data, { cancelToken: source.token });

  promise.cancel = () => {
    source.cancel('Query was cancelled');
  };

  return promise;
};

export const put = (url, data) => {
  const source = axios.CancelToken.source();

  const promise = apiClient().put(url, data, { cancelToken: source.token });

  promise.cancel = () => {
    source.cancel('Query was cancelled');
  };

  return promise;
};

export const get = (url, queryParams, otherConfig) => {
  const source = axios.CancelToken.source();
  const promise = apiClient().get(url, { params: queryParams, cancelToken: source.token, ...otherConfig });

  promise.cancel = () => {
    source.cancel('Query was cancelled');
  };

  return promise;
};

export const del = (url) => {
  const source = axios.CancelToken.source();
  const promise = apiClient().delete(url, { cancelToken: source.token });

  promise.cancel = () => {
    source.cancel('Query was cancelled');
  };

  return promise;
};

export const delWithBody = (url, data) => {
  const source = axios.CancelToken.source();
  const promise = apiClient().delete(url, { cancelToken: source.token, data });

  promise.cancel = () => {
    source.cancel('Query was cancelled');
  };

  return promise;
};
