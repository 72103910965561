import React, { useState, useEffect } from 'react';
import {
  ListItem, List
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import FormFieldFactory from 'components/Form/FormFieldFactory';
import useLoading from 'context/LoadingContext';
import SnackMessage from 'components/SnackMessage/SnackMessage';
import { REACT_APP_PORTAL_API } from 'config';
import useConnectErrorHandler from 'helpers/errorHandlers';
import { post, put } from 'helpers/query/fetchers';
import Form from 'components/Form/Form';
import API from 'common/api';

const initialFormValue = {
  default_threshold: '',
  value: ''
};

const initialFormErrorValue = {
  default_threshold: [],
  value: []
};

const DeviceThresholdCreate = ({
  title, button, iconButton, data = null, formType = 'create', refetch = null, deviceType = null
}) => {
  const [form, setForm] = useState(initialFormValue);
  const [formError, setFormError] = useState(initialFormErrorValue);
  const { enqueueSnackbar } = useSnackbar();
  const { setLoading } = useLoading();
  const { handleError } = useConnectErrorHandler();

  useEffect(() => {
    if (data) {
      setForm(data);
      setFormError(initialFormErrorValue);
    }
    return () => {};
  }, [data]);

  const onChangeValue = (value, name) => {
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = (handleClose, setSubmitting) => {
    setLoading(true);
    setSubmitting(true);

    const payload = JSON.parse(JSON.stringify(form));
    if (form.default_threshold) payload.default_threshold = form.default_threshold.id;
    if (payload.device) payload.device = payload.device.id;

    let url;
    let msg = '';
    if (formType === 'create') {
      url = `${REACT_APP_PORTAL_API}${API.DEVICE_THRESHOLD}`;
      post(url, payload)
        .then((result) => {
          handleClose(true);
          setForm(initialFormValue);
          setFormError(initialFormErrorValue);
          setSubmitting(false);

          msg = `DEVICE THRESHOLD ${result.data.default_threshold.name} has been Created.`;
          refetch();

          enqueueSnackbar(msg, {
            content: (key) => <SnackMessage id={key} message={msg} variant="success" />
          });
        })
        .catch((error) => {
          setLoading(false);
          setSubmitting(false);
          setFormError(error.response.data);
          handleError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (formType === 'update') {
      url = `${REACT_APP_PORTAL_API}${API.DEVICE_THRESHOLD}${data.id}/`;
      put(url, payload)
        .then((result) => {
          handleClose(true);
          setForm(initialFormValue);
          setFormError(initialFormErrorValue);
          setSubmitting(false);

          setForm(result.data);
          msg = `DEVICE THRESHOLD ${result.data.default_threshold.name} has been Updated.`;
          refetch();
          enqueueSnackbar(msg, {
            content: (key) => <SnackMessage id={key} message={msg} variant="success" />
          });
        })
        .catch((error) => {
          setLoading(false);
          setSubmitting(false);
          setFormError(error.response.data);
          handleError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const isError = (error) => error && error.length > 0;

  const renderForm = () => (
    <List disablePadding>
      <ListItem disableGutters>
        <FormFieldFactory
          type="select"
          key="device"
          name="default_threshold"
          label="Threshold"
          async
          config={{
            id_field: 'id',
            label_field: 'name',
            url: `${REACT_APP_PORTAL_API}${API.DEFAULT_THRESHOLD}`,
            params: deviceType ? `?device_type=${deviceType}` : ''
          }}
          value={form.default_threshold}
          onChange={onChangeValue}
          required
          error={isError(formError.default_threshold)}
          helperText={formError.default_threshold ? formError.default_threshold[0] : null}
        />
      </ListItem>
      <ListItem disableGutters>
        <FormFieldFactory
          type="string"
          key="value"
          name="value"
          label="Value"
          // maxLength={fields.name.max_length}
          value={form.value}
          onChange={onChangeValue}
          required
          multiline
          error={isError(formError.value)}
          helperText={formError.value ? formError.value[0] : null}
          placeholder="use doublequotes for json keys"
        />
      </ListItem>
    </List>
  );

  return (
    <Form title={title} button={button} iconButton={iconButton} renderForm={renderForm} submitForm={handleSubmit} />
  );
};

export default DeviceThresholdCreate;
