import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Close, ShowChart } from '@material-ui/icons';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Dialog,
  DialogContent,
  Hidden,
  Drawer,
  Slide,
  Card,
  CardHeader,
  Typography,
  CardContent,
  FormControl
} from '@material-ui/core';
import Panel from '../Graph/Panel';

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: '60vw',
    overflowX: 'hidden',
    maxWidth: '60vw',
    height: '100vh'
    // padding: theme.spacing(3)
  },
  containerDialog: {
    minWidth: '100vw',
    overflowX: 'hidden',
    maxWidth: '100vw',
    height: '100vh'
    // padding: theme.spacing(3)
  },
  listItem: {
    color: theme.palette.primary.main,
  },
}));

const Transition = React.forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);

const LinkGraph = ({
  interfaceId,
  interfaceName,
  device,
  handleOptionClose = null
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [dateRange, setDateRange] = useState('24h');

  const handleChange = (event, value) => {
    setDateRange(value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    handleOptionClose();
  };

  const dateRangeButtons = [
    <ToggleButton key={1} value="1h" disabled={dateRange === '1h'}>
      <Typography>1h</Typography>
    </ToggleButton>,
    <ToggleButton key={2} value="12h" disabled={dateRange === '12h'}>
      <Typography>12h</Typography>
    </ToggleButton>,
    <ToggleButton key={3} value="24h" disabled={dateRange === '24h'}>
      <Typography>24h</Typography>
    </ToggleButton>,
    <ToggleButton key={4} value="7d" disabled={dateRange === '7d'}>
      <Typography>7d</Typography>
    </ToggleButton>,
    <ToggleButton key={5} value="30d" disabled={dateRange === '30d'}>
      <Typography>30d</Typography>
    </ToggleButton>
  ];

  return (
    <>
      <ListItem
        button
        id="ViewGraphButton"
        onClick={() => handleClickOpen()}
        variant="standard"
      >
        <ListItemIcon className={classes.listItem}><ShowChart /></ListItemIcon>
        <ListItemText primary="View Graph" />
      </ListItem>

      <Hidden mdUp>
        <Dialog id="LinkGraph-dialog" onClose={handleClose} open={open} TransitionComponent={Transition} fullScreen>
          <DialogContent style={{ padding: 0 }}>
            <Card className={classes.containerDialog}>
              <CardHeader
                disableTypography
                title={<Typography variant="h4">{device.hostname}</Typography>}
                subheader={(
                  <Typography variant="h5" color="textSecondary">
                    Interface: {interfaceName}
                  </Typography>
                )}
                action={(
                  <IconButton id="GraphCloseButton" onClick={() => handleClose()}>
                    <Close />
                  </IconButton>
                )}
                style={{ paddingBottom: 0 }}
              />
              <CardContent style={{ overflow: 'auto' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <ToggleButtonGroup
                    key="toggle-buttongroup-2"
                    size="small"
                    value={dateRange}
                    exclusive
                    onChange={handleChange}
                  >
                    {dateRangeButtons}
                  </ToggleButtonGroup>
                  <span style={{ flex: 1 }} />
                  <FormControl style={{ margin: '8px', minWidth: 200 }}>
                    <Typography variant="h6" color="textSecondary">
                      {interfaceName}
                    </Typography>
                  </FormControl>
                </div>
                <Panel interfaceId={interfaceId} interface={interfaceName} device={device} dateRange={dateRange} />
              </CardContent>
            </Card>
          </DialogContent>
        </Dialog>
      </Hidden>

      <Hidden smDown>
        <Drawer anchor="right" onClose={handleClose} open={open}>
          <Card className={classes.container}>
            <CardHeader
              disableTypography
              title={<Typography variant="h4">{device.hostname}</Typography>}
              subheader={(
                <Typography variant="h5" color="textSecondary">
                  Interface: {interfaceName}
                </Typography>
              )}
              action={(
                <IconButton id="GraphCloseButton" onClick={() => handleClose()}>
                  <Close />
                </IconButton>
              )}
              style={{ paddingBottom: 0 }}
            />
            <CardContent style={{ overflow: 'auto' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <ToggleButtonGroup
                  key="toggle-buttongroup-2"
                  size="small"
                  value={dateRange}
                  exclusive
                  onChange={handleChange}
                >
                  {dateRangeButtons}
                </ToggleButtonGroup>

              </div>
              <Panel interfaceId={interfaceId} interfaceName={interfaceName} device={device} dateRange={dateRange} />
            </CardContent>
          </Card>
        </Drawer>
      </Hidden>
    </>
  );
};

export default LinkGraph;
