export const getDeviceFeature = ({ type, hostname }) => {
  let feature;
  if (type === 'switch') {
    if (hostname.toUpperCase().startsWith('ULA')) {
      feature = 'share';
    } else if (hostname.toUpperCase().startsWith('ULP')) {
      feature = 'secure';
    }
  }
  return feature;
};

export default {
  getDeviceFeature
};
