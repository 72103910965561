import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Box, Grid, Typography } from '@material-ui/core';
import Progress from 'components/Progress/Progress';
import { makeStyles } from '@material-ui/styles';
import { getBackInterfaceStatusUrl } from 'helpers/url';
import useUser from 'hooks/useUser';
import useDetail from 'hooks/useDetail';
import API from 'common/api';
import PAGES from 'common/pages';
import { PlaceHolderText } from 'components/PlaceholderCard/PlaceholderCard';
import InterfaceCompression from './InterfaceCompression';
import InterfaceDecompression from './InterfaceDecompression';
import InterfaceCompressionToolbar from './InterfaceCompressionToolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(5)
  }
}));

const InterfaceStatusConfigure = () => {
  const height = '80vh';
  const { id, iid } = useParams();
  const classes = useStyles();
  const location = useLocation();
  const [{ data: userData }] = useUser();

  const query = {
    storeKey: `interface-status-${id}-device-configuration-${iid}`,
    apiEndpoint: `${API.INTERFACE_COMPRESSION_DYNAMIC(iid)}s/`,
  };

  const [{
    data, isError, isFetching, refetch, error
  }] = useDetail(query.storeKey, query.apiEndpoint);

  const renderData = () => (
    <Box px={3} py={1}>
      <Grid container spacing={3} alignItems="stretch">
        <Grid item lg={6} xs={12}>
          <InterfaceCompression
            compression={data.compressions}
            uncompressedMac={data.uncompressed_mac.local}
            refetch={refetch}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <InterfaceDecompression
            decompression={data.decompressions}
            uncompressedMac={data.uncompressed_mac.remote}
            refetch={refetch}
          />
        </Grid>
      </Grid>
    </Box>
  );

  if (isFetching) {
    return (
      <div className={classes.root}>
        <Grid container spacing={3} alignItems="stretch">
          <Grid item lg={12} xs={12}>
            <Progress />
          </Grid>
        </Grid>
      </div>
    );
  }

  if (data === null || iid === 'no-selected') {
    return <PlaceHolderText content="Interface ID Not found. Please select an item." height={height} />;
  }

  if (isError) {
    return (
      <PlaceHolderText
        content={error?.response?.status === 501
          ? 'This interface doesn\'t manage (de)compressions'
          : 'Error getting interface information.'}
        height={height}
      />
    );
  }

  if (data) {
    return (
      <div className={classes.root}>
        <InterfaceCompressionToolbar
          title="Interface Configuration"
          isFetching={isFetching}
          refetch={refetch}
          backLink={`${PAGES.INTERFACE_STATUS}/${getBackInterfaceStatusUrl(location.pathname)}`}
          data={data}
          userData={userData}
        />
        <Box px={3}>
          <Typography variant="subtitle1" color="primary">Interface: {data?.interface?.name}</Typography>
          <Typography variant="subtitle1" color="primary">Device: {data?.interface?.device}</Typography>
        </Box>
        {renderData()}
      </div>
    );
  }

  return (
    <div />
  );
};

export default InterfaceStatusConfigure;
