import React from 'react';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  hoverRow: {
    '&:hover': {
      background: theme.palette.background.hover
    }
  },
  drfRow: {
    borderBottom: `1px solid ${theme.palette.divider}`
  }
}));

const DRFRow = ({
  children, hover, onDoubleClick = null, alignItems = 'flex-start'
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems={alignItems}
      className={clsx({
        [classes.drfRow]: true,
        [classes.hoverRow]: hover // only when open === true
      })}
      onDoubleClick={onDoubleClick}
    >
      {children}
    </Grid>
  );
};

export default DRFRow;
