import React from 'react';

import FormTextField from './components/FormFields/FormTextField';
import FormPassword from './components/FormFields/FormPassword';
import FormSwitch from './components/FormFields/FormSwitch';
import FormDateTimeField from './components/FormFields/FormDateTimeField';
// import FormDateField from './components/FormFields/FormDateField';
// import FormDurationField from './components/FormFields/FormDurationField';
import FormSelectSingle from './components/FormFields/FormSelectSingle';
import FormCheckbox from './components/FormFields/FormCheckbox';
// import FormSelectMultiple from './components/FormFields/FormSelectMultiple';
import FormNumberField from './components/FormFields/FormNumberField';
// import FormTextFieldMultiple from './components/FormFields/FormTextFieldMultiple';

const FormFieldFactory = (props) => {
  switch (props.type) {
    case 'string':
      // if (props.many) return <FormTextFieldMultiple {...props} />;
      return <FormTextField {...props} />;
    case 'password':
      return <FormPassword {...props} />;
    case 'boolean':
      return <FormSwitch {...props} />;
    case 'checkbox':
      return <FormCheckbox {...props} />;
    case 'select':
      return <FormSelectSingle {...props} />;
    // case 'select-multiple':
    //   return <FormSelectMultiple {...props} />;
    // case 'duration':
    //   return <FormDurationField {...props} />;
    case 'datetime':
      return <FormDateTimeField {...props} />;
    // case 'date':
    //   return <FormDateField {...props} />;
    case 'number':
    case 'integer':
    case 'float':
      return <FormNumberField {...props} />;
    default:
      return <div />;
  }
};

export default FormFieldFactory;
