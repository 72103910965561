import React from 'react';
import { Grid } from '@material-ui/core';

const DRFCell = ({ children, width }) => (
  <Grid item xs={width} style={{ padding: '16px' }}>
    {children}
  </Grid>
);

export default DRFCell;
