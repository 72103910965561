// WarningMessageContext.js
import React, { useState, useContext, useEffect } from 'react';
import { REACT_APP_ENVIRONMENT } from 'config';

export const WarningMessageContext = React.createContext();

export const WarningMessageProvider = ({ children }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (REACT_APP_ENVIRONMENT === 'staging') {
      setOpen(true);
    }
  }, []);

  return <WarningMessageContext.Provider value={{ open, setOpen }}>{children}</WarningMessageContext.Provider>;
};

const useWarningMessage = () => {
  const { open, setOpen } = useContext(WarningMessageContext);

  return {
    open,
    setOpen
  };
};

export default useWarningMessage;
