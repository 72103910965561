import React, { useState } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from '@material-ui/core';
import {
  Card, Typography, IconButton
} from 'bso-ui-kit';
import { Edit, Delete } from '@material-ui/icons';
import API from 'common/api';
import { PlaceHolderText } from 'components/PlaceholderCard/PlaceholderCard';
import Progress from 'components/Progress/Progress';
import useList from 'hooks/useList';
import TablePaginator from 'components/TablePaginator/TablePaginator';
import useUser from 'hooks/useUser';
import { isAppAdmin } from 'helpers/permissions';
import CustomLinearProgress from 'components/CustomLinearProgress/CustomLinearProgress';
import useConnectErrorHandler from 'helpers/errorHandlers';
import { useSnackbar } from 'notistack';
import DeleteConfirmationDialog from 'components/DeleteConfirmationDialog/DeleteConfirmationDialog';
import SnackMessage from 'components/SnackMessage/SnackMessage';
import { del } from 'helpers/query/fetchers';
import { REACT_APP_PORTAL_API } from 'config';
import DefaultThresholdToolbar from './DefaultThresholdToolbar';
import DefaultThresholdCreate from './Form/DefaultThresholdCreate';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2)
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const DefaultThresholdList = () => {
  const classes = useStyles();
  const height = '80vh';
  const [{ data: userData }] = useUser();
  const { handleError } = useConnectErrorHandler();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const query = {
    storeKey: 'default-threshold-list',
    apiEndpoint: API.DEFAULT_THRESHOLD,
  };
  const [{
    data, isLoading, isError, refetch, isFetched, isFetching
  }] = useList(query.storeKey, query.apiEndpoint);

  if (isError || (isFetched && !data)) {
    return (
      <div style={{ height }}>
        <PlaceHolderText variant="h3" content="Error getting default thresholds" height="100%" />
      </div>
    );
  }

  const deleteDefaultThreshold = (id, name) => {
    setLoading(true);
    const url = `${REACT_APP_PORTAL_API}${API.DEFAULT_THRESHOLD}${id}/`;

    del(url)
      .then(() => {
        refetch();
        const msg = `DEFAULT THRESHOLD ${name} has been Deleted.`;

        enqueueSnackbar(msg, {
          content: (key) => <SnackMessage id={key} message={msg} variant="success" />
        });
      })
      .catch((error) => {
        setLoading(false);
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getActionButtons = (threshold) => (
    <Box display="flex" alignItems="center">
      <DefaultThresholdCreate
        title="Update Default Threshold"
        data={threshold}
        iconButton={{
          content: <Edit />,
          color: 'primary',
          size: 'small',
          disabled: isFetching
        }}
        button={{
          content: 'Update',
          variant: 'standard',
          state: 'outlined',
          color: 'primary',
          disabled: isFetching
        }}
        formType="update"
        refetch={refetch}
      />
      <DeleteConfirmationDialog
        id="DeleteDeviceThresholdHeader"
        title="Delete Default Threshold"
        contentText="Are you sure you want to delete this default Threshold config?"
        confirmText="Yes"
      >
        {(confirm) => (
          <Tooltip title="Delete">
            <div>
              <IconButton
                id="DeleteDeviceDefaultThresholdButton"
                size="small"
                color="primary"
                bgTransparent
                disabled={isFetching}
                onClick={confirm(() => deleteDefaultThreshold(threshold.id, threshold.name))}
              >
                <Delete />
              </IconButton>
            </div>
          </Tooltip>
        )}
      </DeleteConfirmationDialog>
    </Box>
  );

  const getThresholdValues = (threshold) => {
    if (threshold.default_value) {
      const thresholdValue = JSON.parse(threshold.default_value);
      return (
        <CustomLinearProgress
          variant="buffer"
          status="ok"
          current={0}
          max={thresholdValue.warning}
          total={thresholdValue.critical}
          mode="threshold"
        />
      );
    }
    return <Typography>---</Typography>;
  };

  const getDependencyValues = (threshold) => {
    if (threshold.default_value) {
      const thresholdValue = JSON.parse(threshold.default_value);
      if (thresholdValue.dependency) {
        const thresholdDependencyValue = thresholdValue.dependency;
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <>
              <Typography variant="body2">
                Object: {thresholdDependencyValue.object ? thresholdDependencyValue.object : 'null'}
              </Typography>
              <Typography variant="body2">
                Rule: {thresholdDependencyValue.rule}
              </Typography>
              <Typography variant="body2">
                Operator: {thresholdDependencyValue.operator}
              </Typography>
            </>
          </div>
        );
      }
    }
    return <Typography>---</Typography>;
  };

  const renderData = () => (
    <Card
      id="DeviceDefaultThresholdsListCard"
      className={clsx(classes.root)}
    >
      <CardContent className={classes.content}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ flex: 1 }} />
        </div>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell><Typography variant="subtitle1">ID</Typography></TableCell>
                  <TableCell><Typography variant="subtitle1">Name</Typography></TableCell>
                  <TableCell><Typography variant="subtitle1">Device Type</Typography></TableCell>
                  <TableCell style={{ minWidth: 300 }}>
                    <Typography variant="subtitle1">Default Value</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle1">Dependency</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle1">Action</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              {data && data.results.length > 0
                ? (
                  <TableBody>
                    {data.results.map((threshold) => (
                      <TableRow
                        title={threshold?.help_text}
                        className={classes.tableRow}
                        hover
                        key={threshold.id}
                      >
                        <TableCell>
                          <Typography color="primary" variant="body2" component="span">
                            {threshold.id}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography color="primary" variant="body2">
                            {threshold.name ? threshold.name : '---'}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography color="primary" variant="body2">
                            {threshold.device_type ? threshold.device_type : '---'}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {getThresholdValues(threshold)}
                        </TableCell>
                        <TableCell>
                          {getDependencyValues(threshold)}
                        </TableCell>
                        <TableCell>
                          {getActionButtons(threshold)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )
                : null}
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      {data ? (
        <CardActions className={classes.actions}>
          <TablePaginator
            count={data.count}
            previous={data.previous}
            next={data.next}
            isFetched={isFetched}
          />
        </CardActions>
      )
        : null}
    </Card>
  );

  if (userData && (userData.is_staff || isAppAdmin(userData.groups))) {
    return (
      <Box p={3}>
        <DefaultThresholdToolbar
          title="Default Thresholds"
          isFetching={isFetching}
          refetch={refetch}
          data={data}
          userData={userData}
        />
        {(!isLoading || !loading)
          ? renderData()
          : <Progress />}
      </Box>
    );
  }

  return <PlaceHolderText content="No permissions to view this page" height={height} />;
};

export default DefaultThresholdList;
