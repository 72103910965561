import React, { useState } from 'react';
import API from 'common/api';
import { useSnackbar } from 'notistack';
import SnackMessage from 'components/SnackMessage/SnackMessage';
import useConnectErrorHandler from 'helpers/errorHandlers';
import useUser from 'hooks/useUser';
import FormFieldFactory from 'components/Form/FormFieldFactory';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

const AdminSwitch = ({ id, isAdmin, groups }) => {
  const { handleError } = useConnectErrorHandler();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState();
  const [checked, setChecked] = useState(isAdmin);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [, setUser] = useUser();

  const setToggle = (value) => {
    setChecked(value);
    setConfirmOpen(true);
  };

  const setIsAdmin = () => {
    setLoading(true);
    const url = API.SET_ADMIN(id, groups[0].name);

    const payload = JSON.parse(JSON.stringify({ is_admin: checked }));

    setUser({ url, data: { ...payload } })
      .then(() => {
        enqueueSnackbar('', {
          content: (key) => (
            <SnackMessage
              id={key}
              message={`Successfuly updated user ID: ${id}.`}
              variant="success"
            />
          )
        });
      })
      .catch((err) => {
        handleError(err);
        // Revert to prev value
        setChecked((prev) => !prev);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <FormFieldFactory
        type="boolean"
        name={`admin-${id}`}
        disabled={loading}
        checked={checked}
        setToggle={setToggle}
      />
      <ConfirmationDialog
        title={`${checked ? 'Admin user?' : 'Not admin user?'}`}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={() => setIsAdmin()}
        onDeny={() => setChecked((prev) => !prev)}
      >
        {`Are you sure you want to change this user to ${checked ? 'admin' : 'not admin'}?`}
      </ConfirmationDialog>
    </>
  );
};

export default AdminSwitch;
