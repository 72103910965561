import React from 'react';
import {
  IconButton,
  Tooltip,
  CircularProgress,
  Box
} from '@material-ui/core';
import { Refresh as RefreshIcon, Add } from '@material-ui/icons';
import { isAppAdmin } from 'helpers/permissions';
import Toolbar from 'components/Toolbar/Toolbar';
import InterfaceUpdate from 'views/Interface/components/Form/InterfaceUpdate';
import InterfaceFilter from './Form/InterfaceFilter';
import InterfaceCreate from './Form/InterfaceCreate';

const InterfaceToolbar = ({
  title = '', isFetching, refetch, backLink, data = null, userData = null,
  selectedInterfaces = null, setSelectedInterfaces = null
}) => (
  <Toolbar
    title={title}
    backLink={backLink}
    action={(
      <>
        {data && (isAppAdmin(userData.groups) || userData.is_staff)
          && selectedInterfaces?.length > 0
          ? (
            <InterfaceUpdate
              title="Edit interface"
              data={data}
              refetch={refetch}
              selectedInterfaces={selectedInterfaces}
              setSelectedInterfaces={setSelectedInterfaces}
            />
          ) : <Box height={64} />}
        <span style={{ paddingLeft: '10px' }} />
        {data && userData.is_staff ? (
          <InterfaceCreate
            title="Create Interface"
            button={{
              content: (
                <>
                  <Add />
                  Create
                </>
              ),
              color: 'primary',
              variant: 'standard',
              state: 'outlined'
            }}
          />
        ) : null}
        {isFetching ? (
          <div style={{
            height: 48, width: 48, display: 'flex', alignItems: 'center', justifyContent: 'center'
          }}
          >
            <CircularProgress size={22} />
          </div>
        ) : (
          <Tooltip title="Sync">
            <IconButton
              id="SyncButton"
              onClick={(e) => {
                e.preventDefault();
                refetch();
              }}
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        )}
        <InterfaceFilter />
      </>
    )}
  />
);

export default InterfaceToolbar;
