import React from 'react';
import Filter from 'components/Filter/Filter';
import { REACT_APP_PORTAL_API } from 'config';
import API from 'common/api';

const DeviceFilter = () => {
  const filterConfig = [
    {
      name: 'search',
      label: 'Search',
      type: 'string'
    },
    {
      name: 'hostname',
      label: 'Hostname',
      type: 'string'
    },
    {
      name: 'feed',
      label: 'Feed',
      type: 'select',
      async: true,
      config: {
        id_field: 'id',
        label_field: 'name',
        url: `${REACT_APP_PORTAL_API}${API.FEED}`
      }
    },
    {
      name: 'ip',
      label: 'IP',
      type: 'string'
    }
  ];

  return <Filter filterConfig={filterConfig} />;
};

export default DeviceFilter;
