import React from 'react';
import {
  Box,
  Grid,
  Card,
  CardHeader, CardContent
} from '@material-ui/core';
import Progress from 'components/Progress/Progress';
import { Typography } from 'bso-ui-kit';
import DRFTable from 'components/DRFTable/DRFTable';
import DRFRow from 'components/DRFTable/DRFRow';
import DRFCell from 'components/DRFTable/DRFCell';
import CustomLinearProgress from 'components/CustomLinearProgress/CustomLinearProgress';
import CustomChip from 'components/CustomChip/CustomChip';

const SecureIngress = ({ data }) => {
  const renderData = () => (
    <Box px={3}>
      <Typography variant="h5">
        Ingress
      </Typography>
      <Grid container spacing={3} alignItems="stretch">
        <Grid item lg={12} xs={12}>
          <Card>
            <CardContent style={{ paddingTop: 0, overflowY: 'auto' }}>
              <DRFTable>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Status</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <CustomChip
                      label={data?.backup?.rxLink ? 'Up' : 'Down'}
                      size="small"
                      color={data?.backup?.rxLink ? 'success' : 'error'}
                    />
                  </DRFCell>
                </DRFRow>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Received Frames</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data.lane.landsideInFrameCount}</Typography>
                  </DRFCell>
                </DRFRow>
              </DRFTable>
            </CardContent>
            <CardHeader
              disableTypography
              title={<Typography variant="h6">Backup Trunk</Typography>}
              style={{ paddingBottom: 0 }}
            />
            <CardContent style={{ paddingTop: 0, overflowY: 'auto' }}>
              <div style={{ paddingBottom: '16px' }}>
                <CustomLinearProgress
                  variant="buffer"
                  status={(data.lane.encapBufferTotalSize !== 0) ? 'ok' : 'error'}
                  current={(data.lane.encapBufferDataWidth / 8)
                    * data.lane.encapBufferUsage}
                  max={(data.lane.encapBufferDataWidth / 8)
                    * data.lane.encapBufferMaxUsage < data.lane.encapBufferUsage
                    ? data.lane.encapBufferUsage : data.lane.encapBufferMaxUsage}
                  total={(data.lane.encapBufferDataWidth / 8)
                    * data.lane.encapBufferMaxUsage > data.lane.encapBufferTotalSize
                    ? data.lane.encapBufferMaxUsage : data.lane.encapBufferTotalSize}
                />
              </div>
              <DRFTable>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Encap Frames</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data.laneBackup.encapInFrameCount}</Typography>
                  </DRFCell>
                </DRFRow>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Valid Frames</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data.laneBackup.encapOutFrameCount}</Typography>
                  </DRFCell>
                </DRFRow>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Corrupted Frames</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data.laneBackup.encapInErrorCount}</Typography>
                  </DRFCell>
                </DRFRow>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Truncated Frames</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data.laneBackup.encapMtuErrorCount}</Typography>
                  </DRFCell>
                </DRFRow>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Dropped Frames</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data.laneBackup.encapDroppedFrameCount}</Typography>
                  </DRFCell>
                </DRFRow>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Out Sequence Number</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data.laneBackup.encapOutSeqNum}</Typography>
                  </DRFCell>
                </DRFRow>
              </DRFTable>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <>
      {
        data ? renderData()
          : <Progress />
      }
    </>
  );
};

export default SecureIngress;
