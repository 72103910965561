import React, { useState, useEffect } from 'react';
import {
  List, ListItem, CircularProgress
} from '@material-ui/core';
import API from 'common/api';
import { useSnackbar } from 'notistack';
import useUser from 'hooks/useUser';
import { Skeleton } from '@material-ui/lab';
import useConnectErrorHandler from 'helpers/errorHandlers';
import SnackMessage from 'components/SnackMessage/SnackMessage';
import { useQueryClient } from '@tanstack/react-query';
import { Button, FormField } from 'bso-ui-kit';
import { ArrowForward } from '@material-ui/icons';

const initialFormState = {
  first_name: '',
  last_name: '',
};

const initialFormErrorState = {
  email: '',
  first_name: '',
  last_name: '',
};

const ProfileForm = () => {
  const [{ data: user, isLoading }, setUser] = useUser();
  const queryClient = useQueryClient();
  const { handleError } = useConnectErrorHandler();
  const [formState, setFormState] = useState(initialFormState);
  const [formErrorState, setFormErrorState] = useState(initialFormErrorState);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (name, value) => {
    setFormState({ ...formState, [name]: value });
  };

  const isError = (error) => Boolean(error);

  useEffect(() => {
    if (user) {
      setFormState({
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name
      });
    }
  }, [user]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setUser({ url: API.UPDATE_PROFILE, data: { ...formState } })
      .then((res) => {
        queryClient.setQueryData(['user'], (prev) => ({ ...prev, ...res.data }));
        enqueueSnackbar('', {
          content: (key) => (
            <SnackMessage
              data-testid="profile-change-msg"
              id={key}
              message="Profile changes has been saved."
              variant="success"
            />
          )
        });
      }).catch((err) => {
        setFormErrorState(err.response.data);
        handleError(err);
      }).finally(() => {
        setLoading(false);
      });
  };

  const isFormChanged = () => (formState.first_name === user.first_name
    && formState.last_name === user.last_name) || (!formState.first_name || !formState.last_name);

  if (isLoading) {
    return (
      <List disablePadding>
        <ListItem style={{ marginTop: 8, marginBottom: 8 }}>
          <Skeleton height={40} width="100%" />
        </ListItem>
        <ListItem style={{ marginTop: 8, marginBottom: 8 }}>
          <Skeleton height={40} width="100%" />
        </ListItem>
        <ListItem style={{ marginTop: 8, marginBottom: 8 }}>
          <Skeleton height={40} width="100%" />
        </ListItem>
      </List>
    );
  }

  if (user) {
    return (
      <div>
        <form
          onSubmit={handleSubmit}
        >
          <List disablePadding>
            <ListItem style={{ marginTop: 8, marginBottom: 8 }}>
              <FormField
                data-testid="first_name-input"
                label="First name"
                name="first_name"
                onChange={handleChange}
                fullWidth
                type="string"
                variant="outlined"
                value={formState.first_name}
                error={isError(formErrorState.first_name)}
                helperText={formErrorState.first_name ? formErrorState.first_name : null}
              />
            </ListItem>
            <ListItem style={{ marginTop: 8, marginBottom: 8 }}>
              <FormField
                data-testid="last_name-input"
                label="Last name"
                name="last_name"
                onChange={handleChange}
                fullWidth
                type="string"
                variant="outlined"
                value={formState.last_name}
                error={isError(formErrorState.last_name)}
                helperText={formErrorState.last_name ? formErrorState.last_name : null}
              />
            </ListItem>
            <ListItem style={{ marginTop: 8, marginBottom: 8 }}>
              <FormField
                data-testid="email-input"
                label="Email address"
                name="email"
                onChange={(e) => e.preventDefault()}
                type="string"
                variant="outlined"
                fullWidth
                disabled
                value={user.email}
              />
            </ListItem>
          </List>
          <div style={{ display: 'flex', marginTop: 40 }}>
            <Button
              id="UpdateProfileButton"
              variant="cta"
              color="primary"
              state="solid"
              disabled={loading || isFormChanged()}
              endIcon={!loading ? <ArrowForward /> : <CircularProgress size={20} />}
              type="submit"
            >
              Save changes
            </Button>
          </div>
        </form>
      </div>
    );
  }

  return <div />;
};

export default ProfileForm;
