import React from 'react';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';

const FormTextField = ({
  id,
  key,
  name,
  label,
  autoFocus = false,
  helperText,
  placeholder,
  required,
  multiline,
  maxLength,
  readOnly,
  disabled,
  error,
  value,
  onChange,
  variant,
  rows,
  onFocus,
  onBlur,
  'data-testid': testid
}) => {
  const handleChange = (event) => {
    onChange(event.target.value, name);
  };

  return (
    <NoSsr>
      <TextField
        id={id}
        key={key}
        name={name}
        label={label}
        helperText={helperText}
        placeholder={placeholder}
        autoFocus={autoFocus}
        variant={variant}
        fullWidth
        multiline={multiline}
        rows={rows}
        value={value}
        required={required}
        readOnly={readOnly}
        disabled={disabled}
        onChange={handleChange}
        error={error}
        inputProps={{
          'data-testid': testid, maxLength, onFocus, onBlur, readOnly
        }}
      />
    </NoSsr>
  );
};

export default FormTextField;
