import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { useLocation } from 'react-router-dom';
import PaginatorLabel from './PaginatorLabel';
import PreviousPaginator from './PreviousPaginator';
import NextPaginator from './NextPaginator';

const useStyles = makeStyles(() => ({
  controls: {
    display: 'flex',
    minWidth: 50,
    alignItems: 'center',
    justifyContent: 'flex-start'
  }
}));

const TablePaginator = ({
  count, previous, next, isFetched
}) => {
  const classes = useStyles();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const offset = queryParams.get('offset') || 0;
  const limit = parseInt(queryParams.get('limit'), 10) || 10;

  return (
    <div className={classes.controls}>
      <PaginatorLabel count={count} limit={limit} offset={offset} />
      <PreviousPaginator previous={previous} isFetched={isFetched} />
      <NextPaginator next={next} isFetched={isFetched} />
    </div>
  );
};

export default TablePaginator;
