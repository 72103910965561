import React from 'react';
import { Typography, IconButton, Tooltip } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router-dom';

const Toolbar = ({
  title = '', backLink, backLink2, action
}) => {
  const history = useHistory();
  const location = useLocation();

  return (
    <div
      id="ToolbarContainer"
      style={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: '8px'
      }}
    >
      {backLink ? (
        <Tooltip title="Go back to list">
          <IconButton
            onClick={() => history.push({
              pathname: backLink,
              search: location.search
            })}
            style={{ marginRight: 8 }}
          >
            <ArrowBack />
          </IconButton>
        </Tooltip>
      ) : null}
      {backLink2 ? (
        <Tooltip title="Go back">
          <IconButton
            onClick={() => history.push(backLink2)}
            style={{ marginRight: 8 }}
          >
            <ArrowBack />
          </IconButton>
        </Tooltip>
      ) : null}
      <Typography id="HeaderTitle" variant="h3" color="primary">{title}_</Typography>
      <span style={{ flex: 1 }} />
      {action}
    </div>
  );
};

export default Toolbar;
