import {
  Avatar, Box, CardContent, CardHeader, Divider, List,
  ListItem, ListItemAvatar, ListItemText, Typography
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import { Card } from 'bso-ui-kit';
import useUser from 'hooks/useUser';
import React from 'react';
import AccountToolbar from './components/AccountToolbar';
import ChangePasswordForm from './components/ChangePasswordForm';
import ProfileForm from './components/ProfileForm';

const useStyles = makeStyles((theme) => ({
  avatarBig: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    backgroundColor: theme.palette.primary.main
  }
}));

const Account = () => {
  const classes = useStyles();
  const [{ data, isLoading }] = useUser();

  return (
    <>
      <Box p={3}>
        <AccountToolbar id="AccountProfileTitle" title="Account profile" />
        <List id="UserAvatarInfo">
          <ListItem>
            <ListItemAvatar>
              <Avatar className={classes.avatarBig} />
            </ListItemAvatar>
            <ListItemText
              disableTypography
              primary={
                isLoading ? <Skeleton height={28} width={128} />
                  : (
                    <Typography noWrap variant="h3" color="primary">
                      {data && `${data.first_name} ${data.last_name}`}
                    </Typography>
                  )
              }
              secondary={
                isLoading ? <Skeleton height={28} width={180} />
                  : (
                    <Typography noWrap variant="body1" color="textSecondary">
                      {data && `${data.email}`}
                    </Typography>
                  )
              }
              style={{ marginLeft: 16 }}
            />
          </ListItem>
        </List>
        <div style={{ width: 600, minWidth: 400, marginTop: 24 }}>
          <Card id="ProfileCard">
            <CardHeader title={<Typography variant="h3">Profile</Typography>} />
            <CardContent>
              <ProfileForm />
            </CardContent>
          </Card>
          <Box marginTop={4} marginBottom={4}>
            <Divider />
          </Box>
          <Card id="ChangePasswordCard">
            <CardHeader title={<Typography variant="h3">Change password</Typography>} />
            <CardContent>
              <ChangePasswordForm />
            </CardContent>
          </Card>
        </div>
      </Box>
    </>
  );
};

export default Account;
