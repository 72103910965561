import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Box, Grid } from '@material-ui/core';
import PAGES from 'common/pages';
import { Typography, ExternalLink } from 'bso-ui-kit';

const useStyles = makeStyles(() => ({
  root: {
    // padding: theme.spacing(4)
    display: 'flex',
    justifyContent: 'center',
  }
}));

const Footer = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box display="flex" alignItems="center">
          <Box>
            <Typography variant="body2" color="primary">
              &copy;{' '}
              2022 BSO All rights reserved
            </Typography>
          </Box>
          <span style={{ flex: 1 }} />
          <Box marginRight={2}>
            <ExternalLink
              underline="none"
              component="a"
              href={PAGES.BSO_TERMS_OF_USE}
            >
              <Typography variant="body2" color="primary">
                Terms of use
              </Typography>
            </ExternalLink>
          </Box>
          <Box marginRight={2}>
            <ExternalLink
              underline="none"
              component="a"
              href={PAGES.BSO_PRIVACY_POLICY}
            >
              <Typography variant="body2" color="primary">
                Privacy policy
              </Typography>
            </ExternalLink>
          </Box>
          <Box marginRight={2}>
            <ExternalLink
              underline="none"
              component="a"
              href={PAGES.BSO_COOKIE_POLICY}
            >
              <Typography variant="body2" color="primary">
                Cookie policy
              </Typography>
            </ExternalLink>
          </Box>
          <Box marginRight={2}>
            <ExternalLink
              underline="none"
              component="a"
              href={PAGES.BSO_GROUP_POLICY}
            >
              <Typography variant="body2" color="primary">
                Group policy
              </Typography>
            </ExternalLink>
          </Box>
        </Box>
      </Grid>
    </div>
  );
};

export default Footer;
