import React from 'react';
import { useParams } from 'react-router-dom';
import {
  Grid, Box
} from '@material-ui/core';
import useUser from 'hooks/useUser';
import API from 'common/api';
import useDetail from 'hooks/useDetail';
import { PlaceHolderText } from 'components/PlaceholderCard/PlaceholderCard';
import PAGES from 'common/pages';
import DeviceToolbar from './DeviceToolbar';
import DeviceGeneralInfo from './Info/DeviceGeneralInfo';
import DeviceThreshold from './Info/DeviceThreshold';

const DeviceInfo = () => {
  const { id } = useParams();
  const height = '80vh';
  const [{ data: userData }] = useUser();

  const query = {
    storeKey: `device-detail-${id}`,
    apiEndpoint: `${API.DEVICE}${id}/`,
  };
  const [{
    data, isLoading, isFetching, isError, refetch
  }] = useDetail(query.storeKey, query.apiEndpoint);

  if (data) {
    return (
      <Box p={3}>
        <DeviceToolbar
          title="Device Information"
          isFetching={isFetching}
          refetch={refetch}
          backLink={PAGES.DEVICE_MGMT}
          data={data}
          userData={userData}
        />
        <Grid container spacing={3} alignItems="stretch">
          <Grid item lg={5} xs={12}>
            <DeviceGeneralInfo data={data} isFetching={isFetching} refetch={refetch} />
          </Grid>
          <Grid item lg={7} xs={12}>
            <DeviceThreshold deviceId={id} deviceType={data.type} />
          </Grid>
        </Grid>

      </Box>
    );
  }

  if (isLoading) return <PlaceHolderText content="Loading..." height={height} />;

  if (data === null || id === 'no-selected') {
    return <PlaceHolderText content="Device ID Not found. Please select an item." height={height} />;
  }

  if (isError) {
    return <PlaceHolderText id="ErrorDeviceContentText" content="Error getting device information." height={height} />;
  }

  return <div />;
};

export default DeviceInfo;
