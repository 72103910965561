const tileLayer = {
  // attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> BSO RFPORTAL contributors',
  attribution: '&copy; <a href="http://osm.org/copyright">OpenMapTiles</a> BSO RFPORTAL contributors',
  maxNativeZoom: 10,
  minNativeZoom: 10,
  minZoom: 9,
  // maxZoom: 12,
  maxZoom: 20,
  zoom: 20,
  // url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
  // url: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png'
  // url: 'https://{s}.tile.thunderforest.com/transport-dark/{z}/{x}/{y}.png'
  // url: 'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png'
  url: 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png'
};

export default tileLayer;
