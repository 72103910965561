import useConnectErrorHandler from 'helpers/errorHandlers';
import { get } from 'helpers/query/fetchers';
import { useQuery } from '@tanstack/react-query';

const useDetail = (key, apiEndpoint, config, failSilently = false) => {
  const { handleError } = useConnectErrorHandler();
  const getDataList = () => get(apiEndpoint).then((res) => res.data).catch((err) => {
    if (!failSilently) {
      handleError(err);
    }
    throw err;
  });

  const detail = useQuery([key], getDataList, { ...config, staleTime: 30000 });

  return [{ ...detail }];
};

export default useDetail;
