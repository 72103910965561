import React from 'react';
import Filter from 'components/Filter/Filter';

const DefaultThresholdFilter = () => {
  const filterConfig = [
    {
      name: 'search',
      label: 'Search',
      type: 'string'
    },
    {
      name: 'name',
      label: 'name',
      type: 'string'
    },
    {
      name: 'device_type',
      label: 'Device Type',
      type: 'select',
      async: false,
      config: {
        id_field: 'id',
        label_field: 'name',
        selectOptions: [
          { name: 'switch', id: 'switch', value: 'switch' },
          { name: 'antenna', id: 'antenna', value: 'antenna' }
        ]
      }
    }
  ];

  return <Filter filterConfig={filterConfig} />;
};

export default DefaultThresholdFilter;
