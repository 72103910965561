import { get } from 'helpers/query/fetchers';
import { useQuery } from '@tanstack/react-query';

const useGraph = (key, apiEndpoint, queryParams) => {
  const getMetrics = () => get(apiEndpoint, queryParams).then((res) => res.data);

  const metrics = useQuery([key], getMetrics);
  return [{ ...metrics }];
};

export default useGraph;
