import React, { useEffect } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import PAGES from 'common/pages';
import LinearLoading from 'common/LinearLoading/LinearLoading';
import useAuth from 'hooks/useAuth';

export default function SignOut() {
  const { isSignedIn, signOut } = useAuth();
  const history = useHistory();

  useEffect(() => {
    signOut();
    setTimeout(
      () => {
        history.push({ pathname: PAGES.SIGN_IN });
      },
      2000
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isSignedIn()) {
    return (
      <LinearLoading title="Signing out..." />
    );
  }

  return <Redirect to={PAGES.SIGN_IN} />;
}
