import React, {
  useState, useEffect, useCallback
} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { get } from 'helpers/query/fetchers';

const SelectMultipleFilter = ({
  value,
  name,
  label,
  onChange,
  async,
  disabled,
  config,
  error,
  variant,
  helperText,
  autoFocus = false
}) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const loading = open && options.length === 0;

  const getOptionLabels = useCallback(
    (item) => {
      const labelObject = {};
      if (Array.isArray(config.label_field)) {
        config.label_field.forEach((labelFieldItem) => {
          labelObject[labelFieldItem] = item[labelFieldItem];
        });
      } else {
        labelObject[config.label_field] = item[config.label_field];
      }
      return labelObject;
    },
    [config]
  );

  const getAsyncData = useCallback(
    (search) => {
      if (config) {
        get(`${config.url}?search=${search}`)
          .then((res) => {
            const tmpList = [];
            res.data.results.forEach((item) => {
              const labelObject = getOptionLabels(item);
              tmpList.push({ [config.id_field]: item[config.id_field], ...labelObject });
            });
            setOptions(tmpList);
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.log(err);
          });
      }
    },
    [config, getOptionLabels]
  );

  const handleChange = (event, selectedValue) => {
    event.preventDefault();

    if (selectedValue.length > 0) {
      // const ids = convertToFilterParam(selectedValue);
      onChange(selectedValue, name);
    } else {
      onChange([], name);
      setSelected([]);
    }
  };

  const handleInputChange = (event) => {
    if (event) {
      getAsyncData(event.target.value || '');
    }
  };

  useEffect(() => {
    if (config && config.selectOptions) setOptions(config.selectOptions);
  }, [config]);

  useEffect(() => {
    if (async) {
      if (!loading) {
        return undefined;
      }
      getAsyncData('');
      return () => {};
    }

    return () => {};
  }, [loading, async, getAsyncData]);

  useEffect(() => {
    if (value.length > 0) {
      if (value[0]) {
        setSelected(value);
      } else {
        setSelected([]);
      }
    } else {
      setSelected([]);
    }
  }, [value]);

  if (async) {
    return (
      <Autocomplete
        id={`form-select-${name}`}
        style={{ width: '100%' }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        autoHighlight
        disabled={disabled}
        filterSelectedOptions
        multiple
        value={selected}
        onChange={(event, newValue) => handleChange(event, newValue)}
        onInputChange={handleInputChange}
        getOptionSelected={(option, newValue) => (newValue
          ? option[config.id_field] === newValue[config.id_field] : null)}
        getOptionLabel={(option) => {
          if (Array.isArray(config.label_field)) {
            let newLabel = '';
            config.label_field.forEach((labelFieldItem) => {
              newLabel = `${newLabel} ${option[labelFieldItem]}`;
            });
            return newLabel;
          }
          return option[config.label_field];
        }}
        renderOption={(option) => {
          if (Array.isArray(config.label_field)) {
            let newLabel = '';
            config.label_field.forEach((labelFieldItem) => {
              newLabel = `${newLabel} ${option[labelFieldItem]}`;
            });
            return newLabel;
          }
          return option[config.label_field];
        }}
        options={options}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            fullWidth
            variant={variant}
            autoFocus={autoFocus}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
            error={error}
            helperText={helperText}
          />
        )}
      />
    );
  }

  return (
    <Autocomplete
      id={name}
      style={{ width: '100%' }}
      autoSelect
      clearOnEscape
      value={selected}
      disabled={disabled}
      autoHighlight
      filterSelectedOptions
      multiple
      onChange={(event, newValue) => handleChange(event, newValue)}
      getOptionSelected={(option, newValue) => (newValue
        ? option[config.id_field] === newValue[config.id_field] : null)}
      getOptionLabel={(option) => {
        if (Array.isArray(config.label_field)) {
          let newLabel = '';
          config.label_field.forEach((labelFieldItem) => {
            newLabel = `${newLabel} ${option[labelFieldItem]}`;
          });
          return newLabel;
        }
        return option ? option[config.label_field] : null;
      }}
      renderOption={(option) => {
        if (Array.isArray(config.label_field)) {
          let newLabel = '';
          config.label_field.forEach((labelFieldItem) => {
            newLabel = `${newLabel} ${option[labelFieldItem]}`;
          });
          return newLabel;
        }
        return option[config.label_field];
      }}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          label={label}
          fullWidth
          variant={variant}
          autoFocus={autoFocus}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'disabled' // disable autocomplete and autofill
          }}
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
};

export default SelectMultipleFilter;
