// LoadingContext.js
import React, { useState, useContext } from 'react';

export const LoadingContext = React.createContext();

export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  return <LoadingContext.Provider value={{ loading, setLoading }}>{children}</LoadingContext.Provider>;
};

const useLoading = () => {
  const { loading, setLoading } = useContext(LoadingContext);

  return {
    loading,
    setLoading
  };
};

export default useLoading;
