import React from 'react';
import { useSnackbar } from 'notistack';

import auth from 'common/auth';
import { useHistory, useLocation } from 'react-router-dom';
import SnackMessage from 'components/SnackMessage/SnackMessage';
import PAGES from 'common/pages';

const msg401 = 'Unauthorized Access. Please sign in.';

const useConnectErrorHandler = () => {
  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const handleSignInError = (error) => {
    if (error && error.response.data.detail) {
      enqueueSnackbar('', {
        content: (key) => (
          <SnackMessage
            data-testid="signin-error-message"
            id={key}
            message={error.response.data.detail}
            variant="error"
          />
        )
      });
    } else {
      enqueueSnackbar('', {
        content: (key) => (
          <SnackMessage
            data-testid="signin-error-message"
            id={key}
            message={error.response.data}
            variant="error"
          />
        )
      });
    }
  };

  const handleError = (error) => {
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          if (error.message && !error.response.data) {
            enqueueSnackbar(error.message, {
              content: (key) => <SnackMessage id={key} message={error.message} variant="error" />
            });
          } else if (error.response.data.non_field_errors) {
            error.response.data.non_field_errors.forEach((item) => {
              enqueueSnackbar(item, {
                content: (key) => <SnackMessage id={key} message={item} variant="error" />
              });
            });
          }
          break;
        case 401:
          history.push(PAGES.SIGN_OUT, { from: location });
          auth.logout(() => {
            enqueueSnackbar(msg401, {
              content: (key) => <SnackMessage id={key} message={msg401} variant="error" />
            });
          });
          break;
        case 403:
          if (error && error.response.data.detail) {
            enqueueSnackbar('', {
              content: (key) => <SnackMessage id={key} message={error.response.data.detail} variant="error" />
            });
          }
          break;
        default:
          break;
      }
    }
  };

  return { handleSignInError, handleError };
};

export default useConnectErrorHandler;
