import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { PlaceHolderText } from 'components/PlaceholderCard/PlaceholderCard';
import API from 'common/api';
import PAGES from 'common/pages';
import useList from 'hooks/useList';

const InterfaceStatusView = () => {
  const location = useLocation();
  const history = useHistory();
  const query = {
    storeKey: 'feeds',
    apiEndpoint: API.FEED
  };
  const [{
    data, isLoading, isError
  }] = useList(query.storeKey, query.apiEndpoint);

  if (isLoading) {
    return <div><PlaceHolderText content="Loading..." height="80vh" /></div>;
  }

  if (isError) {
    return <div><PlaceHolderText content="There was an unexpected Error." height="80vh" /></div>;
  }

  if (data.count === 0) {
    return <div><PlaceHolderText content="NO FEED TO DISPLAY" height="80vh" /></div>;
  }

  history.push({
    pathname: `${PAGES.INTERFACE_STATUS}/${data.results[0].id}`,
    search: location.search
  });

  return <div />;
};

export default InterfaceStatusView;
