import { makeStyles } from '@material-ui/styles';
import React from 'react';
import LeftView from './LeftView';

const drawerWidth = 320;
const useStyles = makeStyles(() => ({
  rightComponent: {
    minWidth: 1000,
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth})px`
  }
}));

const SplitView = ({ config }) => {
  const classes = useStyles();
  const RightComponent = config.rightComponent;

  return (
    <>
      <LeftView config={config} />
      <div className={classes.rightComponent}>
        <RightComponent />
      </div>
    </>
  );
};
export default SplitView;
