export const isPermissionAllowed = (str, permissions) => {
  const [module, access] = str.split('.');
  if (permissions) {
    if (module in permissions) {
      if (permissions[module].includes(access)) {
        return true;
      }
    }
  }
  return false;
};

export const isAppAdmin = (groups) => {
  let match = false;
  Object.keys(groups).forEach((key) => {
    if (groups[key].name.includes('_admins')) {
      match = true;
    }
  });

  return match;
};

export default { isPermissionAllowed, isAppAdmin };
