import React, { useState, useEffect } from 'react';
import {
  ListItem, List
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import FormFieldFactory from 'components/Form/FormFieldFactory';
import useLoading from 'context/LoadingContext';
import SnackMessage from 'components/SnackMessage/SnackMessage';
import { REACT_APP_PORTAL_API } from 'config';
import useConnectErrorHandler from 'helpers/errorHandlers';
import { post, put } from 'helpers/query/fetchers';
import Form from 'components/Form/Form';
import API from 'common/api';

const initialFormValue = {
  name: '',
  device_type: 'switch',
  help_text: '',
  default_value: ''
};

const initialFormErrorValue = {
  name: [],
  device_type: [],
  help_text: [],
  default_value: []
};

const DefaultThresholdCreate = ({
  title, button, iconButton, data = null, formType = 'create', refetch = null
}) => {
  const [form, setForm] = useState(initialFormValue);
  const [formError, setFormError] = useState(initialFormErrorValue);
  const { enqueueSnackbar } = useSnackbar();
  const { setLoading } = useLoading();
  const { handleError } = useConnectErrorHandler();

  useEffect(() => {
    if (data) {
      setForm(data);
      setFormError(initialFormErrorValue);
    }
    return () => {};
  }, [data]);

  const onChangeValue = (value, name) => {
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = (handleClose, setSubmitting) => {
    setLoading(true);
    setSubmitting(true);
    const payload = JSON.parse(JSON.stringify(form));

    if (form.device_type) payload.device_type = form.device_type.id;

    let url;
    let msg = '';
    if (formType === 'create') {
      url = `${REACT_APP_PORTAL_API}${API.DEFAULT_THRESHOLD}`;
      post(url, payload)
        .then((result) => {
          handleClose(true);
          setForm(initialFormValue);
          setFormError(initialFormErrorValue);
          setSubmitting(false);

          refetch();
          msg = `DEFAULT THRESHOLD ${result.data.name} has been Created.`;

          enqueueSnackbar(msg, {
            content: (key) => <SnackMessage id={key} message={msg} variant="success" />
          });
        })
        .catch((error) => {
          setLoading(false);
          setSubmitting(false);
          setFormError(error.response.data);
          handleError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (formType === 'update') {
      url = `${REACT_APP_PORTAL_API}${API.DEFAULT_THRESHOLD}${data.id}/`;
      put(url, payload)
        .then((result) => {
          handleClose(true);
          setForm(initialFormValue);
          setFormError(initialFormErrorValue);
          setSubmitting(false);

          setForm(result.data);
          msg = `DEFAULT THRESHOLD ${result.data.name} has been Updated.`;
          refetch();
          enqueueSnackbar(msg, {
            content: (key) => <SnackMessage id={key} message={msg} variant="success" />
          });
        })
        .catch((error) => {
          setLoading(false);
          setSubmitting(false);
          setFormError(error.response.data);
          handleError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const isError = (error) => error && error.length > 0;

  const renderForm = () => (
    <List disablePadding>
      <ListItem disableGutters>
        <FormFieldFactory
          type="string"
          key="name"
          name="name"
          label="Name"
          value={form.name}
          onChange={onChangeValue}
          autoFocus
          required
          error={isError(formError.name)}
          helperText={formError.name ? formError.name[0] : null}
        />
      </ListItem>
      <ListItem disableGutters>
        <FormFieldFactory
          type="select"
          key="device_type"
          name="device_type"
          label="Device Type"
          config={{
            id_field: 'id',
            label_field: 'name',
            selectOptions: [
              { name: 'switch', id: 'switch' },
              { name: 'antenna', id: 'antenna' }
            ]
          }}
          required
          disableClearable
          value={form.device_type}
          onChange={onChangeValue}
          error={isError(formError.device_type)}
          helperText={formError.device_type ? formError.device_type[0] : null}
        />
      </ListItem>
      <ListItem disableGutters>
        <FormFieldFactory
          type="string"
          key="help_text"
          name="help_text"
          label="Help Text"
          value={form.help_text ? form.help_text : ''}
          onChange={onChangeValue}
          error={isError(formError.help_text)}
          helperText={formError.help_text ? formError.help_text[0] : null}
        />
      </ListItem>
      <ListItem disableGutters>
        <FormFieldFactory
          type="string"
          key="default_value"
          name="default_value"
          label="Default Value"
          value={form.default_value}
          onChange={onChangeValue}
          required
          multiline
          error={isError(formError.default_value)}
          helperText={formError.default_value ? formError.default_value[0] : null}
          placeholder="use doublequotes for json keys"
        />
      </ListItem>
    </List>
  );

  return (
    <Form title={title} button={button} iconButton={iconButton} renderForm={renderForm} submitForm={handleSubmit} />
  );
};

export default DefaultThresholdCreate;
