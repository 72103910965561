import React from 'react';
import {
  Box, Dialog, DialogContent, DialogContentText
} from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import { Typography, IconButton } from 'bso-ui-kit';
import Close from '@material-ui/icons/Close';
import useWarningMessage from 'context/WarningMessageContext';

const WarningMessage = () => {
  const { open, setOpen } = useWarningMessage();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box display="flex" p={3}>
          <Box display="flex" alignItems="center">
            <Box paddingTop="6px" marginRight={1}> <Warning /></Box>
            <Typography variant="h3" color="primary">
              Staging environment
            </Typography>
          </Box>
          <span style={{ flex: 1 }} />
          <IconButton onClick={handleClose} color="primary">
            <Close />
          </IconButton>
        </Box>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This app is deployed in staging environment,
            but feeds, ports and interfaces data are connected to production.
            <br />
            <br />
            <Typography variant="body1" color="error">Do *NOT* update feeds, ports and interfaces data.</Typography>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default WarningMessage;
