import React, {
  useState, useEffect, useCallback
} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { get } from 'helpers/query/fetchers';

const FormSelectSingle = ({
  value = '',
  name,
  label,
  onChange,
  async,
  disabled,
  config,
  error,
  openOnFocus,
  helperText,
  autoFocus = false,
  disableClearable = false,
  className,
  variant,
  readOnly
}) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState(null);
  const loading = open && options.length === 0;

  const getOptionLabels = useCallback(
    (item) => {
      const labelObject = {};
      if (Array.isArray(config.label_field)) {
        config.label_field.forEach((labelFieldItem) => {
          labelObject[labelFieldItem] = item[labelFieldItem];
        });
      } else {
        labelObject[config.label_field] = item[config.label_field];
      }
      return labelObject;
    },
    [config]
  );

  const getAsyncData = useCallback(
    (search) => {
      const url = config.params ? `${config.url}${config.params}&search=${search}` : `${config.url}?search=${search}`;
      get(url)
        .then((res) => {
          const tmpList = [];
          res.data.results.forEach((item) => {
            const labelObject = getOptionLabels(item);
            tmpList.push({ [config.id_field]: item[config.id_field], ...labelObject });
          });
          setOptions(tmpList);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    [config, getOptionLabels]
  );

  const handleChange = (event, selectedValue) => {
    event.preventDefault();
    if (selectedValue) {
      onChange(selectedValue, name);
    } else {
      onChange('', name);
      setSelected(null);
    }
  };

  const handleInputChange = (event) => {
    if (event) {
      getAsyncData(event.target.value);
    }
  };

  useEffect(() => {
    if (config && config.selectOptions) setOptions(config.selectOptions);
  }, [config]);

  useEffect(() => {
    if (async) {
      if (!loading) {
        return undefined;
      }
      getAsyncData('');
      return () => {};
    }

    return () => {};
  }, [loading, async, getAsyncData]);

  useEffect(() => {
    if (value !== '') {
      setSelected(value);
    } else {
      setSelected(null);
    }
  }, [value]);

  if (async) {
    return (
      <Autocomplete
        id="FormSelectSingle"
        style={{ width: '100%' }}
        open={open}
        // onOpen={() => {
        //   setOpen(true);
        // }}
        onOpen={() => !readOnly && setOpen(true)}
        onClose={() => {
          setOpen(false);
        }}
        openOnFocus={openOnFocus}
        autoHighlight
        disabled={disabled}
        value={selected}
        // disableClearable={disableClearable}
        disableClearable={disableClearable || readOnly}
        onChange={(event, newValue) => handleChange(event, newValue)}
        onInputChange={handleInputChange}
        getOptionSelected={(option, newValue) => (newValue
          ? option[config.id_field] === newValue[config.id_field] : null)}
        getOptionLabel={(option) => {
          if (Array.isArray(config.label_field)) {
            let newLabel = '';
            config.label_field.forEach((labelFieldItem) => {
              newLabel = `${newLabel} ${option[labelFieldItem]}`;
            });
            return newLabel;
          }
          /*
          PEWPEW - IF RETURNED IS LIST DICT [{id,name}], CHECK SERIALIZER
          sometimes there are trailing commas which returns it as a list instead of a single one
          */
          // if (Array.isArray(option)) {
          //   return option[0][config.label_field];
          // }
          return option[config.label_field];
        }}
        renderOption={(option) => {
          let newLabel = '';
          if (Array.isArray(config.label_field)) {
            config.label_field.forEach((labelFieldItem) => {
              newLabel = `${newLabel} ${option[labelFieldItem]}`;
            });
            return newLabel;
          }
          if (typeof config.label_field === 'function') {
            newLabel = config.label_field(option);
          }
          return option[config.label_field];
        }}
        options={options}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            className={className}
            label={label}
            fullWidth
            variant={variant}
            autoFocus={autoFocus}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
              readOnly
            }}
            error={error}
            helperText={helperText}
          />
        )}
      />
    );
  }
  return (
    <Autocomplete
      id="FormSelectSingle-demo"
      style={{ width: '100%' }}
      open={open}
      // onOpen={() => {
      //   setOpen(true);
      // }}
      onOpen={() => !readOnly && setOpen(true)}
      onClose={() => {
        setOpen(false);
      }}
      openOnFocus={openOnFocus}
      autoHighlight
      disabled={disabled}
      value={selected}
      // disableClearable={disableClearable}
      disableClearable={disableClearable || readOnly}
      onChange={(event, newValue) => handleChange(event, newValue)}
      getOptionSelected={(option, newValue) => {
        if (typeof newValue === 'string') {
          return option.name === newValue;
        }
        return (newValue ? option[config.id_field] === newValue[config.id_field] : null);
      }}
      getOptionLabel={(option) => {
        let newLabel = '';
        if (Array.isArray(config.label_field)) {
          config.label_field.forEach((labelFieldItem) => {
            newLabel = `${newLabel} ${option[labelFieldItem]}`;
          });
          return newLabel;
        }
        if (typeof config.label_field === 'function') {
          newLabel = config.label_field(option);
          return newLabel;
        }
        if (typeof option === 'string') {
          return option;
        }
        return option[config.label_field];
      }}
      renderOption={(option) => {
        let newLabel = '';
        if (Array.isArray(config.label_field)) {
          config.label_field.forEach((labelFieldItem) => {
            newLabel = `${newLabel} ${option[labelFieldItem]}`;
          });
          return newLabel;
        }
        if (typeof config.label_field === 'function') {
          newLabel = config.label_field(option);
          return newLabel;
        }
        return option[config.label_field];
      }}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          className={className}
          label={label}
          fullWidth
          variant={variant}
          autoFocus={autoFocus}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
            readOnly
          }}
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
};

export default FormSelectSingle;
