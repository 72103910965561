import MomentUtils from '@date-io/moment';
import moment from 'moment';

class UTCUtils extends MomentUtils {
  // eslint-disable-next-line class-methods-use-this
  parse(value, format) {
    if (value === '') {
      return null;
    }

    try {
      const theDate = moment.utc(value, format, true);
      if (theDate.isValid()) {
        return theDate.format();
      }
    } catch {
      return null;
    }
    return null;
  }

  date(value) {
    if (value === null) {
      return null;
    }

    const theDate = super.date(value);
    return theDate.utc();
  }
}

export default UTCUtils;
