import React, { useState } from 'react';
import API from 'common/api';
import { useSnackbar } from 'notistack';
import SnackMessage from 'components/SnackMessage/SnackMessage';
import { put } from 'helpers/query/fetchers';
import useConnectErrorHandler from 'helpers/errorHandlers';
import FormFieldFactory from 'components/Form/FormFieldFactory';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

const ActiveSwitch = ({ id, name, isActive }) => {
  const { handleError } = useConnectErrorHandler();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(isActive);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const toggleMiscDetails = (value) => {
    setChecked(value);
    setConfirmOpen(true);
  };

  const setIsActive = () => {
    setLoading(true);
    const url = API.INTERFACE_SET_STATUS(id);
    const payload = JSON.parse(JSON.stringify({ status: checked }));

    put(url, payload)
      .then(() => {
        const msg = `Port ${name} has been ${checked ? 'Activated' : 'Deactivated'} `;
        enqueueSnackbar(msg, {
          content: (key) => <SnackMessage id={key} message={msg} variant="success" />
        });
      })
      .catch((err) => {
        handleError(err);
        // Revert to prev value
        setChecked((prev) => !prev);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <FormFieldFactory
        type="boolean"
        name={`active-${id}`}
        disabled={loading}
        checked={checked}
        setToggle={toggleMiscDetails}
      />
      <ConfirmationDialog
        title={`${checked ? 'Activate port?' : 'Deactivate port?'}`}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={() => setIsActive()}
        onDeny={() => setChecked((prev) => !prev)}
      >
        {`Are you sure you want to ${checked ? 'activate' : 'deactivate'} this port?`}
      </ConfirmationDialog>
    </>

  );
};

export default ActiveSwitch;
