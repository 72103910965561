import React from 'react';
// import { useLocation, Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  Fade,
  Box,
} from '@material-ui/core';
// import useAuth from 'hooks/useAuth';
import { Card, Typography } from 'bso-ui-kit';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    padding: theme.spacing(10)
  },
  title: {
    textAlign: 'center',
    alignItems: 'center',
    fontSize: 48,
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  subtitle: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
    paddingTop: theme.spacing(10),
  },
}));

const NotFound = () => {
  const classes = useStyles();
  // const location = useLocation();
  // const { isSignedIn } = useAuth();
  // const { from } = location.state || { from: { pathname: '/' } };

  // if (isSignedIn()) {
  //   return <Redirect to={from} />;
  // }

  return (
    <div>
      <Fade
        in
        mountOnEnter
        timeout={400}
        unmountOnExit
      >
        <Card color="gradient">
          <Box p={3}>
            <Card color="white">
              <div
                className={classes.form}
              >
                <Typography
                  className={classes.title}
                  data-testid="welcome-message"
                  variant="h1"
                  style={{ fontSize: 140 }}
                >
                  404
                </Typography>
                <div className={classes.subtitle}>
                  <Typography variant="h3">
                    The page you are looking for does not exist.
                  </Typography>
                </div>
              </div>
            </Card>
          </Box>
        </Card>
      </Fade>
    </div>
  );
};

export default NotFound;
