import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';

import {
  ListItem, List, CircularProgress
} from '@material-ui/core';

import API from 'common/api';
import Form from 'components/Form/Form';
import { PlaceHolderText } from 'components/PlaceholderCard/PlaceholderCard';
import SnackMessage from 'components/SnackMessage/SnackMessage';
import { put } from 'helpers/query/fetchers';
import { ArrowForward } from '@material-ui/icons';
import { FormField } from 'bso-ui-kit';

const InterfaceUpdate = ({
  title, data = null, refetch = null, selectedInterfaces = null, setSelectedInterfaces = null
}) => {
  const [form, setForm] = useState();
  const [fields, setFields] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [formError, setFormError] = useState();

  useEffect(() => {
    setLoading(true);
    if (data && selectedInterfaces) {
      const toUpdateInterfaces = [];
      data.results.forEach((item) => {
        if (selectedInterfaces.includes(item.id)) {
          toUpdateInterfaces.push(item);
        }
      });

      setFields(toUpdateInterfaces);
    }

    return () => {};
  }, [data, selectedInterfaces]);

  useEffect(() => {
    if (fields) {
      const tmpForm = {};
      const tmpFormError = {};
      fields.forEach((item) => {
        tmpForm[item.id.toString()] = { id: item.id, description: item.description };
        tmpFormError[item.id.toString()] = [];
      });

      setForm(tmpForm);
      setFormError(tmpFormError);
    }
    setLoading(false);
    return () => {};
  }, [fields]);

  const handleSubmit = (handleClose) => {
    setSubmitting(true);
    const promises = [];
    Object.entries(form).forEach(([interfaceId, updateData]) => {
      const url = API.INTERFACE_SET_DESCRIPTION(interfaceId);
      const payload = JSON.parse(JSON.stringify(updateData));

      const promise = put(url, payload).catch((error) => {
        if (error.response) {
          setSubmitting(false);
          setFormError((prevFormError) => ({ ...prevFormError, [interfaceId]: error.response.data }));
        } else if (error.request) {
          // eslint-disable-next-line no-console
          console.log(error.request);
        } else {
          // eslint-disable-next-line no-console
          console.log(error.message);
        }
      });
      promises.push(promise);
    });

    Promise.all(promises).then((responses) => {
      let hasError = false;
      responses.forEach((item) => {
        if (item === undefined) {
          hasError = true;
        }
      });

      if (hasError) {
        setSubmitting(false);
      } else {
        setForm();
        setSelectedInterfaces([]);
        const msg = 'Interface has been Updated.';
        enqueueSnackbar(msg, {
          content: (key) => <SnackMessage id={key} message={msg} variant="success" />
        });
        handleClose(true);
      }
      refetch();
    });
  };

  const onChangeValue = (name, value) => {
    setForm({ ...form, [name]: { description: value } });
  };

  const isError = (error) => error && error.length !== 0;

  const renderForm = () => (
    <List disablePadding>
      {!loading && fields && form && formError
        ? fields.map((item, index) => (
          <ListItem disableGutters key={item.id}>
            <FormField
              type="string"
              name={item.id.toString()}
              label={`${item.device?.feed?.name ? item.device?.feed?.name : 'Unknown'}
                ${item.device?.hostname} ${item.name} description`}
              value={form[item.id].description}
              autoFocus={index === 0}
              variant="outlined"
              fullWidth
              onChange={onChangeValue}
              error={isError(formError[item.id])}
              helperText={formError[item.id] ? formError[item.id].description : null}
            />
          </ListItem>
        ))
        : <PlaceHolderText content="Loading Form..." height="100%" />}
    </List>
  );

  return (
    <Form
      title={title}
      button={{
        content: 'Update',
        color: 'primary',
        variant: 'standard',
        state: 'outlined',
        disabled: submitting,
        endIcon: submitting ? <CircularProgress size={20} /> : <ArrowForward />
      }}
      renderForm={renderForm}
      submitForm={handleSubmit}
    />
  );
};

export default InterfaceUpdate;
