import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import Grid from '@material-ui/core/Grid';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import UTCUtils from 'helpers/UTCUtils';

const useStyles = makeStyles(() => ({
  textField: {
    width: '100%'
  }
}));

const FormDateTimeField = ({
  id,
  name,
  label,
  helperText,
  placeholder,
  autoFocus = false,
  fullWidth,
  required,
  readOnly,
  disabled,
  clearable,
  disableFuture,
  value = null,
  setDateValue,
  format = 'MM/DD/YYYY HH:mm',
  variant = 'dialog',
  inputVariant,
  disableToolbar,
  error
}) => {
  const classes = useStyles();

  return (
    <NoSsr>
      <MuiPickersUtilsProvider utils={UTCUtils}>
        {/* <MuiPickersUtilsProvider> */}
        <Grid container justify="space-around">
          <KeyboardDateTimePicker
            id={id}
            name={name}
            label={label}
            helperText={helperText}
            placeholder={placeholder}
            fullWidth={fullWidth}
            required={required}
            readOnly={readOnly}
            disabled={disabled}
            clearable={clearable}
            disableFuture={disableFuture}
            value={value}
            onChange={(date) => {
              setDateValue(date, name);
            }}
            autoFocus={autoFocus}
            // minDate={new Date()}
            ampm={false}
            format={format}
            variant={variant}
            inputVariant={inputVariant}
            disableToolbar={disableToolbar}
            className={classes.textField}
            error={error}
          />
        </Grid>
      </MuiPickersUtilsProvider>
    </NoSsr>
  );
};

export default FormDateTimeField;
