import React from 'react';
import {
  Toolbar, Typography,
} from '@material-ui/core';

const AccountToolbar = ({ id = null, title }) => (
  <Toolbar
    style={{
      marginTop: 8,
      minHeight: 48,
      display: 'flex',
      alignItems: 'center',
      marginBottom: 32
    }}
    disableGutters
  >
    <div>
      <Typography id={id} variant="h2" color="primary">{title}_</Typography>
    </div>
    <span style={{ flex: 1 }} />
  </Toolbar>
);

export default AccountToolbar;
