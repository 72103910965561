import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  ListItem, List, ListItemText, ListItemSecondaryAction
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import FormFieldFactory from 'components/Form/FormFieldFactory';
import useLoading from 'context/LoadingContext';
import SnackMessage from 'components/SnackMessage/SnackMessage';
import { REACT_APP_PORTAL_API } from 'config';
import useConnectErrorHandler from 'helpers/errorHandlers';
import PAGES from 'common/pages';
import { post, put } from 'helpers/query/fetchers';
import Form from 'components/Form/Form';
import API from 'common/api';

const initialFormValue = {
  username: '',
  first_name: '',
  last_name: '',
  email: '',
  is_staff: false,
  is_active: false,
  password: ''
};

const initialFormErrorValue = {
  username: [],
  first_name: [],
  last_name: [],
  email: [],
  password: []
};

const UserCreate = ({
  title, button, data = null, formType = 'create', refetch = null
}) => {
  const [form, setForm] = useState(initialFormValue);
  const [formError, setFormError] = useState(initialFormErrorValue);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const { setLoading } = useLoading();
  const { handleError } = useConnectErrorHandler();

  useEffect(() => {
    if (data) {
      setForm(data);
      setFormError(initialFormErrorValue);
    }
    return () => {};
  }, [data]);

  const onChangeValue = (value, name) => {
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = (handleClose, setSubmitting) => {
    setLoading(true);
    setSubmitting(true);
    const payload = JSON.parse(JSON.stringify(form));

    let url;
    let msg = '';
    if (formType === 'create') {
      url = `${REACT_APP_PORTAL_API}${API.USER}`;
      post(url, payload)
        .then((result) => {
          handleClose(true);
          setForm(initialFormValue);
          setFormError(initialFormErrorValue);
          setSubmitting(false);

          history.push({ pathname: `${PAGES.USER_MGMT}/${result.data.id}`, search: location.search });
          msg = `USER ID: ${result.data.id} has been Created.`;

          enqueueSnackbar(msg, {
            content: (key) => <SnackMessage id={key} message={msg} variant="success" />
          });
        })
        .catch((error) => {
          setLoading(false);
          setSubmitting(false);
          setFormError(error.response.data);
          handleError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (formType === 'update') {
      url = `${REACT_APP_PORTAL_API}${API.USER}${data.id}/`;
      put(url, payload)
        .then((result) => {
          handleClose(true);
          setForm(initialFormValue);
          setFormError(initialFormErrorValue);
          setSubmitting(false);

          setForm(result.data);
          msg = `USER ID: ${result.data.id} has been Updated.`;
          refetch();
          enqueueSnackbar(msg, {
            content: (key) => <SnackMessage id={key} message={msg} variant="success" />
          });
        })
        .catch((error) => {
          setLoading(false);
          setSubmitting(false);
          setFormError(error.response.data);
          handleError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const isError = (error) => error && error.length > 0;

  const renderForm = () => (
    <List disablePadding>
      <ListItem disableGutters>
        <FormFieldFactory
          type="string"
          key="username"
          name="username"
          label="Username"
          // maxLength={fields.name.max_length}
          value={form.username}
          onChange={onChangeValue}
          autoFocus
          required
          error={isError(formError.username)}
          helperText={formError.username ? formError.username[0] : null}
        />
      </ListItem>
      <ListItem disableGutters>
        <FormFieldFactory
          type="string"
          key="first_name"
          name="first_name"
          label="First Name"
          // maxLength={fields.name.max_length}
          value={form.first_name}
          onChange={onChangeValue}
          required
          error={isError(formError.first_name)}
          helperText={formError.first_name ? formError.first_name[0] : null}
        />
      </ListItem>
      <ListItem disableGutters>
        <FormFieldFactory
          type="string"
          key="last_name"
          name="last_name"
          label="Last Name"
          // maxLength={fields.name.max_length}
          value={form.last_name}
          onChange={onChangeValue}
          error={isError(formError.last_name)}
          helperText={formError.last_name ? formError.last_name[0] : null}
        />
      </ListItem>
      <ListItem disableGutters>
        <FormFieldFactory
          type="string"
          key="email"
          name="email"
          label="Email"
          // maxLength={fields.name.max_length}
          value={form.email}
          onChange={onChangeValue}
          error={isError(formError.email)}
          helperText={formError.email ? formError.email[0] : null}
        />
      </ListItem>
      {formType === 'create' ? (
        <ListItem disableGutters>
          <FormFieldFactory
            type="string"
            key="password"
            name="password"
            label="Default Password"
            // maxLength={fields.name.max_length}
            value={form.password}
            onChange={onChangeValue}
            required
            error={isError(formError.password)}
            helperText={formError.password ? formError.password[0] : null}
          />
        </ListItem>
      ) : null}
      <ListItem disableGutters>
        <ListItemText primary="Is Staff" />
        <ListItemSecondaryAction>
          <FormFieldFactory
            type="boolean"
            key="is_staff"
            name="is_staff"
            value="is_staff"
            checked={form.is_staff}
            setToggle={onChangeValue}
            required
          />
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem disableGutters>
        <ListItemText primary="Is Active" />
        <ListItemSecondaryAction>
          <FormFieldFactory
            type="boolean"
            key="is_active"
            name="is_active"
            value="is_active"
            checked={form.is_active}
            setToggle={onChangeValue}
            required
          />
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );

  return (
    <Form title={title} button={button} renderForm={renderForm} submitForm={handleSubmit} />
  );
};

export default UserCreate;
