import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { LoadingProvider } from 'context/LoadingContext';
import { WarningMessageProvider } from 'context/WarningMessageContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SnackbarProvider } from 'notistack';
import { InterfaceProvider } from 'views/Interface/InterfaceContext';
import { ThemeProvider } from 'bso-ui-kit';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import Routes from './Routes';

const browserHistory = createBrowserHistory();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // query options
      retry: 0,
    },
    mutations: {
      // mutation options
      retry: 0,
    },
  },
});

const App = () => {
  const notistackRef = React.createRef();

  return (
    <ThemeProvider>
      <QueryClientProvider client={queryClient}>
        <LoadingProvider>
          <SnackbarProvider
            preventDuplicate
            maxSnack={3}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            ref={notistackRef}
          >
            <InterfaceProvider>
              <WarningMessageProvider>
                <Router history={browserHistory}>
                  <Routes />
                </Router>
              </WarningMessageProvider>
            </InterfaceProvider>
          </SnackbarProvider>
        </LoadingProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
