import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  ListItem, List, ListItemText, ListItemSecondaryAction
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import FormFieldFactory from 'components/Form/FormFieldFactory';
import useLoading from 'context/LoadingContext';
import SnackMessage from 'components/SnackMessage/SnackMessage';
import { REACT_APP_PORTAL_API } from 'config';
import useConnectErrorHandler from 'helpers/errorHandlers';
import PAGES from 'common/pages';
import { post, put } from 'helpers/query/fetchers';
import Form from 'components/Form/Form';
import API from 'common/api';

const initialFormValue = {
  name: '',
  device: '',
  description: '',
  type: '',
  is_active: false,
};

const initialFormErrorValue = {
  name: [],
  device: [],
  description: [],
  type: [],
};

const InterfaceCreate = ({
  title, button, data = null, formType = 'create', refetch = null
}) => {
  const [form, setForm] = useState(initialFormValue);
  const [formError, setFormError] = useState(initialFormErrorValue);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const { setLoading } = useLoading();
  const { handleError } = useConnectErrorHandler();

  useEffect(() => {
    if (data) {
      setForm(data);
      setFormError(initialFormErrorValue);
    }
    return () => {};
  }, [data]);

  const onChangeValue = (value, name) => {
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = (handleClose, setSubmitting) => {
    setLoading(true);
    setSubmitting(true);
    const payload = JSON.parse(JSON.stringify(form));

    if (form.device) payload.device = form.device.id;
    if (form.type) payload.type = form.type.id;

    let url;
    let msg = '';
    if (formType === 'create') {
      url = `${REACT_APP_PORTAL_API}${API.INTERFACE}`;
      post(url, payload)
        .then((result) => {
          handleClose(true);
          setForm(initialFormValue);
          setFormError(initialFormErrorValue);
          setSubmitting(false);

          history.push({ pathname: `${PAGES.INTERFACE_MGMT}/${result.data.id}`, search: location.search });
          msg = `INTERFACE ID: ${result.data.id} has been Created.`;

          enqueueSnackbar(msg, {
            content: (key) => <SnackMessage id={key} message={msg} variant="success" />
          });
        })
        .catch((error) => {
          setLoading(false);
          setSubmitting(false);
          setFormError(error.response.data);
          handleError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (formType === 'update') {
      url = `${REACT_APP_PORTAL_API}${API.INTERFACE}${data.id}/`;
      put(url, payload)
        .then((result) => {
          handleClose(true);
          setForm(initialFormValue);
          setFormError(initialFormErrorValue);
          setSubmitting(false);

          setForm(result.data);
          msg = `INTERFACE ID: ${result.data.id} has been Updated.`;
          refetch();
          enqueueSnackbar(msg, {
            content: (key) => <SnackMessage id={key} message={msg} variant="success" />
          });
        })
        .catch((error) => {
          setLoading(false);
          setSubmitting(false);
          setFormError(error.response.data);
          handleError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const isError = (error) => error && error.length > 0;

  const renderForm = () => (
    <List disablePadding>
      <ListItem disableGutters>
        <FormFieldFactory
          type="string"
          key="name"
          name="name"
          label="Name"
          // maxLength={fields.name.max_length}
          value={form.name}
          onChange={onChangeValue}
          autoFocus
          required
          error={isError(formError.name)}
          helperText={formError.name ? formError.name[0] : null}
        />
      </ListItem>
      <ListItem disableGutters>
        <FormFieldFactory
          type="select"
          key="device"
          name="device"
          label="Device"
          async
          config={{
            id_field: 'id',
            label_field: 'hostname',
            url: `${REACT_APP_PORTAL_API}${API.DEVICE}`,
          }}
          value={form.device}
          onChange={onChangeValue}
          required
          error={isError(formError.device)}
          helperText={formError.device ? formError.device[0] : null}
        />
      </ListItem>
      {formType === 'update' ? (
        <ListItem disableGutters>
          <FormFieldFactory
            type="string"
            key="description"
            name="description"
            label="Description"
            // maxLength={fields.name.max_length}
            value={form.description}
            onChange={onChangeValue}
            error={isError(formError.description)}
            helperText={formError.description ? formError.description[0] : null}
          />
        </ListItem>
      ) : null}
      <ListItem disableGutters>
        <FormFieldFactory
          type="select"
          key="type"
          name="type"
          label="Interface Type"
          async
          config={{
            id_field: 'id', // fields.type.choice_source.id_field,
            label_field: 'name', // x => `${x.name} | ${x.code}`,
            url: `${REACT_APP_PORTAL_API}${API.INTERFACE_TYPE}`, // fields.type.choice_source.url
          }}
          value={form.type}
          onChange={onChangeValue}
          required
          error={isError(formError.type)}
          helperText={formError.type ? formError.type[0] : null}
        />
      </ListItem>
      <ListItem disableGutters>
        <ListItemText primary="Is Active" />
        <ListItemSecondaryAction>
          <FormFieldFactory
            type="boolean"
            key="is_active"
            name="is_active"
            value="is_active"
            checked={form.is_active}
            setToggle={onChangeValue}
            required
          />
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );

  return (
    <Form title={title} button={button} renderForm={renderForm} submitForm={handleSubmit} />
  );
};

export default InterfaceCreate;
