const GraphOptions = {
  chart: {
    animations: {
      enabled: false
    },
    markers: {
      size: 0
    },
    background: '#424242',
    height: 'auto'
  },
  legend: {
    offsetY: 4,
  },
  theme: {
    mode: 'light',
    palette: 'palette1',
  },
  stroke: {
    show: true,
    curve: 'straight',
    lineCap: 'butt',
    colors: undefined,
    width: 1,
    dashArray: 0
  },
  dataLabels: {
    enabled: false
  },
  yaxis: {
    show: true,
    showAlways: true,
    floating: true,
    labels: {
      offsetX: 64,
      offsetY: -5,
      align: 'right'
    }
  },
  xaxis: {
    type: 'datetime',
    // min: new Date('01 Mar 2012').getTime(),
    tickAmount: 6
  },
  tooltip: {
    x: {
      format: 'dd MMM yyyy HH:mm'
    }
  }
};

export default GraphOptions;
