import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Tooltip, CircularProgress } from '@material-ui/core';
import { Refresh as RefreshIcon, Add, Devices } from '@material-ui/icons';
import PAGES from 'common/pages';
import { IconButton } from 'bso-ui-kit';
import Toolbar from 'components/Toolbar/Toolbar';
import DefaultThresholdFilter from './Form/DefaultThresholdFilter';
import DefaultThresholdCreate from './Form/DefaultThresholdCreate';

const DefaultThresholdToolbar = ({
  title = '', isFetching, refetch, backLink, data = null, userData = null
}) => {
  const history = useHistory();
  const location = useLocation();

  return (
    <Toolbar
      title={title}
      backLink={backLink}
      action={(
        <>
          {data && userData.is_staff ? (
            <DefaultThresholdCreate
              title="Create Default Threshold"
              button={{
                content: (
                  <>
                    <Add />
                    Create
                  </>
                ),
                color: 'primary',
                variant: 'standard',
                state: 'outlined'
              }}
              refetch={refetch}
            />
          ) : null}
          {isFetching ? (
            <div style={{
              height: 48, width: 48, display: 'flex', alignItems: 'center', justifyContent: 'center'
            }}
            >
              <CircularProgress size={22} />
            </div>
          ) : (
            <Tooltip title="Sync">
              <div>
                <IconButton
                  id="SyncButton"
                  onClick={(e) => {
                    e.preventDefault();
                    refetch();
                  }}
                >
                  <RefreshIcon />
                </IconButton>
              </div>
            </Tooltip>
          )}
          <DefaultThresholdFilter />
          <Tooltip title="View Devices">
            <div>
              <IconButton
                onClick={() => {
                  history.push({
                    pathname: `${PAGES.DEVICE_MGMT}`,
                    search: location.search
                  });
                }}
              >
                <Devices />
              </IconButton>
            </div>
          </Tooltip>
        </>
      )}
    />
  );
};
export default DefaultThresholdToolbar;
