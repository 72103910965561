import React from 'react';

import API from 'common/api';
import Filter from 'components/Filter/Filter';
import { REACT_APP_PORTAL_API } from 'config';

const LogsFilter = () => {
  const filterConfig = [
    {
      name: 'method',
      label: 'Method',
      type: 'select-multiple',
      async: false,
      config: {
        id_field: 'value',
        label_field: 'label',
        selectOptions: [
          { label: 'GET', value: 'GET' },
          { label: 'POST', value: 'POST' },
          { label: 'PUT', value: 'PUT' },
          { label: 'DELETE', value: 'DELETE' }
        ]
      }
    },
    {
      name: 'user',
      label: 'Username',
      type: 'select',
      async: true,
      config: {
        id_field: 'id',
        label_field: (data) => `${data.username}`,
        url: `${REACT_APP_PORTAL_API}${API.USER}`
      }
    },
    // {
    //   name: 'user',
    //   label: 'Email',
    //   type: 'select',
    //   async: true,
    //   config: {
    //     id_field: 'id',
    //     label_field: (data) => `${data.email}`,
    //     url: `${REACT_APP_PORTAL_API}${API.USER}`
    //   }
    // },
    {
      name: 'ip',
      label: 'IP',
      type: 'string'
    },
    {
      name: 'url',
      label: 'URL',
      type: 'string'
    },
    {
      name: 'remarks',
      label: 'Remarks',
      type: 'string'
    },
    {
      name: 'date_from',
      label: 'Date from',
      type: 'datetime'
    },
    {
      name: 'date_to',
      label: 'Date to',
      type: 'datetime'
    },
  ];

  return <Filter filterConfig={filterConfig} />;
};

export default LogsFilter;
