import React from 'react';
import { Switch } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import PAGES from 'common/pages';
import PrivateRouteWithLayout from 'components/RouteWithLayout/PrivateRouteWithLayout';
import InterfaceContent from './InterfaceContent';
import InterfaceStatusInfo from './Information/InterfaceStatusInfo';
import InterfaceStatusConfigure from './Configure/InterfaceStatusConfigure';

const InterfaceStatusDetailView = () => (
  <>
    <Switch>
      <Redirect
        exact
        from={PAGES.INTERFACE_STATUS}
        to={PAGES.INTERFACE_STATUS_DETAIL}
      />
      <PrivateRouteWithLayout
        title="Interface Status_"
        component={InterfaceContent}
        exact
        layout={Box}
        path={PAGES.INTERFACE_STATUS_DETAIL}
      />
      <PrivateRouteWithLayout
        title="Interface Information_"
        component={InterfaceStatusInfo}
        exact
        layout={Box}
        path={PAGES.INTERFACE_STATUS_INFORMATION}
      />
      <PrivateRouteWithLayout
        title="Interface Configuration_"
        component={InterfaceStatusConfigure}
        exact
        layout={Box}
        path={PAGES.INTERFACE_CONFIGURE}
      />
    </Switch>

  </>
);

export default InterfaceStatusDetailView;
