/* eslint-disable class-methods-use-this */
class Auth {
  login(authUser, cb) {
    localStorage.setItem('isSignedIn', authUser.isSignedIn);
    localStorage.setItem('token', authUser.token);
    localStorage.setItem('refreshToken', authUser.refreshToken);
    // localStorage.setItem('refreshToken', user.refreshToken);
    // localStorage.setItem('email', authUser.user.email);
    // localStorage.setItem('name', authUser.user.name);
    // localStorage.setItem('avatar', authUser.user.avatar);
    // localStorage.setItem('permissions', authUser.user.permissions);

    if (cb) {
      cb();
    }
  }

  setUserInfo(user, cb) {
    localStorage.setItem('email', user.email);
    localStorage.setItem('firstName', user.first_name);
    localStorage.setItem('lastName', user.last_name);

    if (cb) {
      cb();
    }
  }

  updateTokens(accessToken, refreshToken) {
    localStorage.setItem('token', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
  }

  logout(cb) {
    localStorage.removeItem('isSignedIn');
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('email');
    localStorage.removeItem('firstName');
    localStorage.removeItem('lastName');

    if (cb) {
      cb();
    }
  }
}

export default new Auth();
