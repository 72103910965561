import React from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import {
  Card, CardHeader, CardContent, CardActions
} from '@material-ui/core';
import { Button, Typography } from 'bso-ui-kit';
import { useSnackbar } from 'notistack';
import DeleteConfirmationDialog from 'components/DeleteConfirmationDialog/DeleteConfirmationDialog';
import { del } from 'helpers/query/fetchers';
import { REACT_APP_PORTAL_API } from 'config';
import { getDeviceFeature } from 'helpers/misc';
import useConnectErrorHandler from 'helpers/errorHandlers';
import useLoading from 'context/LoadingContext';
import Progress from 'components/Progress/Progress';
import SnackMessage from 'components/SnackMessage/SnackMessage';
import API from 'common/api';
import PAGES from 'common/pages';
import useUser from 'hooks/useUser';
import DRFTable from 'components/DRFTable/DRFTable';
import DRFRow from 'components/DRFTable/DRFRow';
import DRFCell from 'components/DRFTable/DRFCell';
import DeviceCreate from '../Form/DeviceCreate';

const DeviceGeneralInfo = ({ data, refetch = null }) => {
  const { id } = useParams();
  const height = '80vh';
  const location = useLocation();
  const history = useHistory();
  const { handleError } = useConnectErrorHandler();
  const { enqueueSnackbar } = useSnackbar();
  const { loading, setLoading } = useLoading();
  const [{ data: userData }] = useUser();

  const deleteDevice = () => {
    setLoading(true);
    const url = `${REACT_APP_PORTAL_API}${API.DEVICE}${id}/`;
    del(url)
      .then(() => {
        history.push({ pathname: `${PAGES.DEVICE_MGMT}`, search: location.search });
        const msg = `DEVICE ID: ${id} has been Deleted.`;

        enqueueSnackbar(msg, {
          content: (key) => <SnackMessage id={key} message={msg} variant="success" />
        });
      })
      .catch((error) => {
        setLoading(false);
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getActionButtons = () => (
    <>
      {data && userData.is_staff ? (
        <DeviceCreate
          title="Update Device"
          data={data}
          button={{
            content: 'Update',
            variant: 'standard',
            state: 'outlined',
            color: 'primary',
            // disabled: isFetching
          }}
          formType="update"
          refetch={refetch}
        />
      ) : (
        <div style={{ paddingTop: '36px' }} />
      )}
      {data && userData.is_staff ? (
        <DeleteConfirmationDialog
          id="DeleteDeviceHeader"
          title="Delete Device"
          contentText="Are you sure you want to delete this Device?"
          confirmText="Yes"
        >
          {(confirm) => (
            <Button
              id="DeleteDeviceButton"
              color="primary"
              variant="standard"
              state="outlined"
              // disabled={isFetching}
              onClick={confirm(deleteDevice)}
            >
              Delete
            </Button>
          )}
        </DeleteConfirmationDialog>
      ) : (
        <div style={{ paddingTop: '36px' }} />
      )}
    </>
  );

  const renderData = () => (
    <Card style={{ height, overflow: 'auto' }}>
      <CardHeader
        title={(
          <Typography variant="h4" color="primary">
            DEVICE ID: {data.id}
          </Typography>
        )}
        style={{ paddingBottom: 0 }}
      />
      <CardContent
        style={{
          paddingTop: 0,
          overflowY: 'auto'
        }}
      >
        <div style={{ paddingBottom: '16px' }}>
          <Typography variant="subtitle1">{data.hostname}</Typography>
        </div>
        <DRFTable>
          <DRFRow>
            <DRFCell width={5}>
              <Typography variant="body2">Feed</Typography>
            </DRFCell>
            <DRFCell width={7}>
              <Typography>{data.feed?.name ? data.feed?.name : '---'}</Typography>
            </DRFCell>
          </DRFRow>
          <DRFRow>
            <DRFCell width={5}>
              <Typography variant="body2">IP</Typography>
            </DRFCell>
            <DRFCell width={7}>
              <Typography>{data.ip ? data.ip : '---'}</Typography>
            </DRFCell>
          </DRFRow>
          <DRFRow>
            <DRFCell width={5}>
              <Typography variant="body2">Port</Typography>
            </DRFCell>
            <DRFCell width={7}>
              <Typography>{data.port ? data.port : '---'}</Typography>
            </DRFCell>
          </DRFRow>
          <DRFRow>
            <DRFCell width={5}>
              <Typography variant="body2">Feature</Typography>
            </DRFCell>
            <DRFCell width={7}>
              <Typography>{data ? getDeviceFeature(data) : '---'}</Typography>
            </DRFCell>
          </DRFRow>
          <DRFRow>
            <DRFCell width={5}>
              <Typography variant="body2">Type</Typography>
            </DRFCell>
            <DRFCell width={7}>
              <Typography>{data.type ? data.type : '---'}</Typography>
            </DRFCell>
          </DRFRow>
          <DRFRow>
            <DRFCell width={5}>
              <Typography variant="body2">Active</Typography>
            </DRFCell>
            <DRFCell width={7}>
              <Typography>{data.is_active ? 'Yes' : 'No'}</Typography>
            </DRFCell>
          </DRFRow>
        </DRFTable>
      </CardContent>
      <CardActions>{getActionButtons()}</CardActions>
    </Card>
  );

  return ((loading) ? <Progress /> : renderData());
};

export default DeviceGeneralInfo;
