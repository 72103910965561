export const getUrlParams = (url) => {
  const params = {};
  const urlValues = url.split('?');
  if (urlValues.length > 1) {
    const vars = urlValues[1].split('&');
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      if (pair[0] && pair[1]) {
        params[pair[0]] = decodeURIComponent(pair[1]);
      }
    }
  }
  return params;
};

export const paramsDictToString = (params) => {
  const queryString = [];
  Object.entries(params).forEach(([key, value]) => {
    queryString.push(`${key}=${value}`);
  });
  return queryString.join('&');
};

export const getModule = (url) => {
  const module = url.split('/');

  if (module && module.length === 3) {
    return module[1];
  }
  if (module && module.length === 4) {
    return `${module[1]}/${module[2]}`;
  }

  return null;
};

export const getParamsStrings = (url) => {
  const splitValue = url.split('?');
  if (splitValue.length > 1) {
    return splitValue[1];
  }
  return null;
};

export const removeDuplicateURLSearchParams = (searchParams) => {
  const newParams = new URLSearchParams();

  searchParams.forEach((value, key) => {
    if (!newParams.has(key)) {
      newParams.append(key, value);
    }
  });

  return newParams;
};

export const getBackInterfaceStatusUrl = (url) => {
  const path = url.split('/');
  return path[2];
};
