import React from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';

const FormCheckbox = ({
  name, label, disabled, value, setToggle, size, color
}) => (
  <FormControlLabel
    control={(
      <Checkbox
        color={color}
        disabled={disabled}
        checked={value}
        value={value}
        size={size}
        onChange={(event) => setToggle(event.target.checked, name)}
      />
      )}
    label={label}
  />
);

export default FormCheckbox;
