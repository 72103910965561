import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';

import { Topbar } from './components';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 64,
    height: '100%',
    paddingLeft: 8,
    paddingRight: 8,
    background: theme.palette.background.paper
  },
  shiftContent: {
    // paddingLeft: 240
  },
  content: {
    height: '100%',
    paddingTop: theme.spacing(1)
  },
  innerMainContent: {
    padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
    height: '100%',
  }
}));

const Main = (props) => {
  const { children } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop
      })}
    >
      <Topbar />
      <main className={classes.content}>
        {children}
      </main>
    </div>
  );
};

export default Main;
