import React, { useEffect } from 'react';
import {
  Toolbar, Typography, Zoom,
} from '@material-ui/core';
import StatusBullet from 'components/StatusBullet/StatusBullet';
import { useParams } from 'react-router-dom';
import useInterface from '../InterfaceContext';
import InterfaceUpdate from './Form/InterfaceUpdate';

const InterfaceToolbar = ({ title, con }) => {
  const { id } = useParams();
  const {
    selectedInterfaces,
    setSelectedInterfaces,
    feedName,
    setFeedName,
    backhaulStatus,
    setBackhaulStatus
  } = useInterface();
  const feed = con.get_related_store_data('feed');
  const interfaceStatus = con.get_related_store_data('interface-status');

  useEffect(() => {
    if (feed && id) {
      feed.results.forEach((item) => {
        if (item.id.toString() === id) {
          setFeedName(item.name);
        }
      });
    } else {
      setFeedName(undefined);
    }
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feed, id]);

  useEffect(() => {
    if (interfaceStatus && id) {
      interfaceStatus.results.forEach((item) => {
        if (item.status.interface === 'et4') {
          setBackhaulStatus(item.status.status);
        }
      });
    } else {
      setBackhaulStatus(undefined);
    }
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interfaceStatus, id]);

  const getBackhaulStatus = () => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Typography variant="overline" component="span" style={{ marginRight: 8 }}>Backhaul Status:</Typography>
      <StatusBullet
        color={backhaulStatus === '' ? 'success' : 'danger'}
        size="lg"
      />
    </div>
  );

  return (
    <Toolbar style={{ display: 'flex', alignItems: 'center' }} disableGutters>
      <Typography variant="h3">{`${title} ${feedName ? `- ${feedName}` : ''}`}</Typography>
      <span style={{ flex: 1 }} />
      {selectedInterfaces.length ? (
        <Zoom in={Boolean(selectedInterfaces.length)}>
          <div style={{ marginRight: 24 }}>
            <InterfaceUpdate
              title="Edit interface"
              data={interfaceStatus}
              con={con}
              selectedInterfaces={selectedInterfaces}
              setSelectedInterfaces={setSelectedInterfaces}
            />
          </div>
          {/* <Avatar style={{ backgroundColor: 'white', marginRight: 40 }}>
            <IconButton color="primary"><Edit /></IconButton>
          </Avatar> */}
        </Zoom>
      ) : null}
      {backhaulStatus !== undefined ? <div>{getBackhaulStatus()}</div> : null}
    </Toolbar>
  );
};

export default InterfaceToolbar;
