const PAGES = {
  BSO: 'https://www.bso.co',
  BSO_CONTACT: 'https://www.bso.co/contact-us',
  BSO_TERMS_OF_USE: 'https://www.bso.co/terms-of-use',
  BSO_PRIVACY_POLICY: 'https://www.bso.co/privacy-policy',
  BSO_COOKIE_POLICY: 'https://www.bso.co/cookie-policy',
  BSO_GROUP_POLICY: 'https://www.bso.co/group-policies',

  SIGN_IN: '/sign-in',
  SIGN_OUT: '/sign-out',
  NOT_FOUND: '/not-found',
  SIGN_UP: '/sign-up',
  CONFIRM_SIGN_UP: '/sign-up/confirm/:code',
  RESEND_CONFIRM_SIGN_UP: '/sign-up/resend-confirmation',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password/:code',
  DASHBOARD: '/dashboard',
  CME: '/cme-status',
  ICE: '/ice-status',
  INTERFACE_STATUS: '/interface-status',
  INTERFACE_STATUS_DETAIL: '/interface-status/:id',
  INTERFACE_STATUS_INFORMATION: '/interface-status/:id/information/:iid',
  INTERFACE_CONFIGURE: '/interface-status/:id/configure/:iid',
  SETTINGS: '/settings',
  INTERFACE_MGMT: '/settings/interface-management',
  INTERFACE_DETAIL: '/settings/interface-management/:id',
  ACCOUNT: '/settings/account',
  USER_MGMT: '/settings/user-management',
  USER_DETAIL: '/settings/user-management/:id',
  DEVICE_MGMT: '/settings/device-management',
  DEVICE_DETAIL: '/settings/device-management/:id',
  DEFAULT_THRESHOLD_MGMT: '/settings/default-threshold',
  NETWORKMAP: '/networkmap',
  LOGS: '/settings/logs'
};

export default PAGES;
